import auth from '@/api/auth'
import control from '@/api/control/index'
import drivers from '@/api/drivers'
import profile from '@/api/profile'
import publicStatic from '@/api/publicStatic'
import quota from '@/api/quota'
import quotaSamples from '@/api/quota-samples'
import reports from '@/api/repotrs'
import settings from '@/api/settings'
import supervisor from '@/api/supervisor'
import terminal from '@/api/terminal'
import timeslots from '@/api/timeslots'

export default {
  auth,
  control,
  terminal,
  quota,
  quotaSamples,
  drivers,
  supervisor,
  timeslots,
  profile,
  publicStatic,
  reports,
  settings,
}
