import * as actionsCommon from '@/store/actions'
import {
  FETCH_EDITOR_TEXT,
  FETCH_UNLOAD_INFO,
  GET_EDITOR_TEXT,
  GET_IS_LOADING_SETTINGS,
  GET_UNLOAD_INFO,
  SET_EDITOR_TEXT,
  SET_IS_LOADING_SETTINGS,
  SET_NEW_SETTINGS,
  SET_UNLOAD_INFO,
  UPDATE_EDITOR_TEXT,
} from '@/views/settings/store/actions'
import { Message } from 'element-ui'
import API from '@/api'

export default {
  state: {
    isLoading: true,
    editorText: '',
    unloadInfo: null,
  },

  getters: {
    [GET_IS_LOADING_SETTINGS]: state => state.isLoading,
    [GET_EDITOR_TEXT]: state => state.editorText || '',
    [GET_UNLOAD_INFO]: state => state.unloadInfo,
  },

  actions: {
    [SET_NEW_SETTINGS]({ commit, dispatch }, data) {
      commit(SET_IS_LOADING_SETTINGS, true)
      API.settings
        .postNewSettings(data)
        .then(() => {
          Message({
            message: 'Настройки успешно сохранены',
            type: 'success',
          })
          dispatch(FETCH_UNLOAD_INFO)
          dispatch(actionsCommon.FETCH_TERMINALS, null, {
            root: true,
          })
        })
        .catch(() =>
          Message({
            message: `Произошла ошибка при сохранении`,
            type: 'error',
          }),
        )
        .finally(() => {
          commit(SET_IS_LOADING_SETTINGS, false)
        })
    },
    [FETCH_EDITOR_TEXT]: ({ commit, rootGetters }, payload) => {
      commit(SET_IS_LOADING_SETTINGS, true)
      let unload_id

      if (!payload) {
        unload_id = rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      } else {
        unload_id = payload.unload_id
      }
      API.settings
        .getEditorText({ unload_id })
        .then(({ data }) => {
          commit(SET_EDITOR_TEXT, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_IS_LOADING_SETTINGS, false)
          }, 1000)
        })
    },

    [FETCH_UNLOAD_INFO]: ({ commit, rootGetters }, payload) => {
      commit(SET_IS_LOADING_SETTINGS, true)
      let unload_id

      if (!payload) {
        unload_id = rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      } else {
        unload_id = payload.unload_id
      }
      API.settings
        .getUnloadInfo(unload_id)
        .then(({ data }) => {
          commit(SET_UNLOAD_INFO, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_IS_LOADING_SETTINGS, false)
          }, 1000)
        })
    },

    [UPDATE_EDITOR_TEXT]: ({ commit, rootGetters }, text) => {
      commit(SET_IS_LOADING_SETTINGS, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      return API.settings
        .postEditorText({ unload_id, text })
        .then(() =>
          Message({
            message: 'Изменения успешно сохранены',
            type: 'success',
          }),
        )
        .catch(error => {
          console.log('error', error)
          Message({
            message: `Произошла ошибка при сохранении`,
            type: 'error',
          })
        })
        .finally(() => {
          setTimeout(() => {
            commit(SET_IS_LOADING_SETTINGS, false)
          }, 1000)
        })
    },
  },

  mutations: {
    [SET_IS_LOADING_SETTINGS](state, payload) {
      state.isLoading = payload
    },
    [SET_EDITOR_TEXT]: (state, payload) => {
      state.editorText = payload.text
    },
    [SET_UNLOAD_INFO]: (state, payload) => {
      state.unloadInfo = payload
    },
  },
}
