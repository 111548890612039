import * as actionsCommon from '@/store/actions'
import API from '@/api'

import * as actions from '../actions'
import { initialPagination } from '@/constants/pagination'
import { preparePagination } from '@/core'
import { showMessageSuccess } from '@/UI/message'

export default {
  state: {
    isLoading: false,
    blacklist: [],
    pagination: initialPagination,
  },
  getters: {
    [actions.GET_BLACKLIST_PAGE]: state => state.blacklist,
    [actions.GET_BLACKLIST_PAGINATION]: state => state.pagination,
    [actions.GET_IS_LOADING_BLACKLIST]: state => state.isLoading,
  },
  actions: {
    [actions.FETCH_BLACKLIST_PAGE]({ commit, rootGetters }, payload) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        terminal_id: terminalCurrentId,
        ...payload,
      }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_BLACKLIST, true)

        API.control.blacklist
          .get(requestData)
          .then(({ headers, data }) =>
            commit(actions.SET_BLACKLIST_PAGE, {
              data,
              pagination: preparePagination(headers),
            }),
          )
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_BLACKLIST, false))
      }
    },
    [actions.SAVE_BLACKLIST](
      { commit, dispatch },
      { requestData, callback, params },
    ) {
      // если пришел ИД - обновляем, если нет - создаем нового
      const method = requestData.id ? 'update' : 'create'

      commit(actions.SET_IS_LOADING_BLACKLIST, true)

      API.control.blacklist[method](requestData)
        .then(() => {
          dispatch(actions.FETCH_BLACKLIST_PAGE, params)
          showMessageSuccess(
            requestData.id
              ? 'Запись успешно обновлена'
              : 'Запись успешно добавлена',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_BLACKLIST, false))
    },
    [actions.DELETE_BLACKLIST]({ commit, dispatch }, { id, callback, params }) {
      commit(actions.SET_IS_LOADING_BLACKLIST, true)

      API.control.blacklist
        .delete(id)
        .then(() => {
          dispatch(actions.FETCH_BLACKLIST_PAGE, params)
          showMessageSuccess(
            id.length > 1 ? 'Записи были удалены' : 'Запись была удалена',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_BLACKLIST, false))
    },
  },
  mutations: {
    [actions.SET_IS_LOADING_BLACKLIST](state, payload) {
      state.isLoading = payload
    },
    [actions.SET_BLACKLIST_PAGE](state, { data, pagination }) {
      state.blacklist = data
      state.pagination = pagination
    },
  },
}
