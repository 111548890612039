import {
  GET_LOGS,
  GET_LOGS_FROM_STATE,
  GET_LOGS_IS_LOADING,
  SET_LOGS,
  SET_LOGS_IS_LOADING,
} from '@/views/manager/store/actions'
import API from '@/api'

export const managerTimeSlotDetailState = {
  logs: [],
  isLoading: true,
}

export const managerTimeSlotDetailGetters = {
  [GET_LOGS_FROM_STATE]: state => state.logs,
  [GET_LOGS_IS_LOADING]: state => state.isLoading,
}

export const managerTimeSlotDetailActions = {
  [GET_LOGS]({ commit }, payload) {
    commit(SET_LOGS_IS_LOADING, true)

    API.timeslots
      .getLogsByTimeslot({ timeslot_id: payload })
      .then(({ data }) => commit(SET_LOGS, data))
      .catch(error => console.log('error', error))
      .finally(() => commit(SET_LOGS_IS_LOADING, false))
  },
}

export const managerTimeSlotDetailMutations = {
  [SET_LOGS](state, payload) {
    state.logs = payload
  },
  [SET_LOGS_IS_LOADING](state, payload) {
    state.isLoading = payload
  },
}
