import { Request } from '@/core'
import { auth } from '@/constants/urls'

export default {
  auth(data) {
    return Request.post(auth.auth, data)
  },
  login(data) {
    return Request.post(auth.login, data)
  },
  sms(phone) {
    return Request.post(auth.sms, phone)
  },
  register(data) {
    return Request.post(auth.register, data)
  },
  reset(data) {
    return Request.post(auth.reset, data)
  },
}
