export const GET_SAMPLES = '[quota-samples/getter] GET_SAMPLES'
export const GET_IS_LOADING_SAMPLES =
  '[quota-samples/getter] GET_IS_LOADING_SAMPLES'
export const ADD_SAMPLE = '[quota-samples/action] ADD_SAMPLE'
export const UPDATE_EXPORTER_SAMPLE =
  '[quota-samples/action] UPDATE_EXPORTER_SAMPLE'
export const DELETE_SAMPLE = '[quota-samples/action] DELETE_SAMPLE'
export const FETCH_SAMPLES = '[quota-samples/action] FETCH_SAMPLES'

// new quotas
export const SET_SAMPLES_LIST = '[quota-samples/mutation] SET_SAMPLES_LIST'
export const SET_IS_LOADING_SAMPLES =
  '[quota-samples/mutation] SET_IS_LOADING_SAMPLES'
