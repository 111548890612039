import { GET_IS_MOBILE, SET_IS_MOBILE } from '@/store/actions'

export default {
  state: {
    isMobile: false,
  },
  getters: {
    [GET_IS_MOBILE]: state => state.isMobile,
  },
  mutations: {
    [SET_IS_MOBILE]: (state, mode) => {
      state.isMobile = mode
    },
  },
}
