export const getPercent = (count, total) => {
  if (count === 0 && total === 0) {
    return '0%'
  }

  return count / (total / 100) + '%'
}

export const getPercentNum = (count, total) => {
  if (count === 0 && total === 0) {
    return 0
  }

  return count / (total / 100)
}
