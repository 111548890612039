import { Request, getParamsUrl } from '@/core'
import { control, settings } from '@/constants/urls'

export default {
  getEditorText: async params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${settings.getEditorText}${paramsFormatted}`)
  },

  getUnloadInfo: async data => Request.get(`${settings.getUnloadInfo}?id=${data}`),

  postEditorText: async data => Request.post(`${settings.setEditorText}`, data),
  postNewSettings: async data => Request.post(`${settings.setNewSettings}`, data),
}
