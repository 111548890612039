import { Request, getParamsUrl } from '@/core'
import { control, drivers } from '@/constants/urls'

export default {
  getCommonSuppliersCheckInn: (driverToken, inn) =>
    Request.get(`${drivers.commonSuppliersCheckInn}?inn=${inn}`, {
      driverToken,
    }),
  getPublicCheckInn: inn =>
    Request.get(`${drivers.commonSuppliersCheckInn}?inn=${inn}`),
  getTerminals: async (driverToken, full_list = 0) =>
    Request.get(`${drivers.terminals}?full_list=${full_list}`, { driverToken }),
  getUserProfile: async ({ token: driverToken }) =>
    Request.get(drivers.getUserProfile, { driverToken }),
  getTimeslotData: async params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${drivers.timeslotData}${paramsFormatted}`)
  },
  getAutocomplete: async ({ token: driverToken }) =>
    Request.get(drivers.getAutocomplete, { driverToken }),
  getCommonSupplierAutocomplete: async ({ token: driverToken }) =>
    Request.get(drivers.getCommonSupplierAutoComplete, { driverToken }),
  getTimeslots: async ({ token: driverToken }) =>
    Request.get(drivers.getTimeslots, { driverToken }),
  getTimeslotsPage: async ({ token: driverToken }, page) =>
    Request.get(`${drivers.getTimeslots}?page=${page}`, { driverToken }),
  getCultures: async ({ token: driverToken }, params) => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${drivers.cultures}${paramsFormatted}`, { driverToken })
  },
  getSuppliers: async ({ token: driverToken }, params) => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${drivers.suppliers}${paramsFormatted}`, {
      driverToken,
    })
  },
  postTimeRequest: (data, driverToken) =>
    Request.post(drivers.createTimeRequest, data, { driverToken }),
  postTimeslotsSubmit: (data, driverToken) =>
    Request.post(drivers.timeslotsSubmit, data, { driverToken }),
  postAutocomplete: (data, driverToken) =>
    Request.post(drivers.createAutocomplete, data, { driverToken }),
  postTimeslotMoveRequest: data =>
    Request.post(drivers.timeslotMoveRequest, data),
  postRemoveTimeslot: (data, driverToken) => {
    const params = new FormData()

    params.append('timeslot_id', data)

    return Request.post(drivers.removeTimeslot, params, driverToken)
  },
}
