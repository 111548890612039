import { GET_DIALOG, SET_DIALOG } from '@/store/actions'

export default {
  state: {
    dialogs: {},
  },
  getters: {
    [GET_DIALOG]: state => name => state.dialogs[name] || {},
  },
  mutations: {
    [SET_DIALOG]: (state, { name, visible = false, data = {} }) => {
      state.dialogs = { ...state.dialogs, [name]: { visible, data } }
    },
  },
}
