import { Request, getParamsUrl } from '@/core'
import { publicStatic } from '@/constants/urls'

export default {
  getStatic: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${publicStatic.publicStatic}${paramsFormatted}`)
  },
  getUnloads: () => Request.get(`${publicStatic.publicUnloadst}`),
}
