import { Request, getParamsUrl } from '@/core'
import { control } from '@/constants/urls'

export default {
  get: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${control.autos}${paramsFormatted}`)
  },
}
