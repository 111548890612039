import { getPercentNum } from '@/core'

const progressColors = {
  filling: '#4CA7D8',
  filled: '#67C23A',
  overflow: '#DB6D39',
}

export const getProgressBarParams = quota => {
  if (quota.count === 0) {
    return {
      percentage: 0,
      title: 'Тайм слоты:',
      color: progressColors.filling,
    }
  }
  const result = getPercentNum(
    Number(quota.count),
    Number(quota.trucks_allowed_count),
  )

  if (result > 100) {
    return {
      percentage: 100,
      title: 'Квота перевыполнена:',
      color: progressColors.overflow,
    }
  }
  if (result === 100) {
    return {
      percentage: 100,
      title: 'Квота выполнена:',
      color: progressColors.filled,
    }
  }
  if (result < 10) {
    return {
      percentage: 10,
      title: 'Тайм слоты:',
      color: progressColors.filling,
    }
  }

  return {
    percentage: result,
    title: 'Тайм слоты:',
    color: progressColors.filling,
  }
}
