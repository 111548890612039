import _ from 'lodash/fp'

export const arrToObj = arr => {
  const array = arr || [],
    obj = {}

  array.map(i => (obj[i.id] = i))

  return obj
}

export const dataToObj = (obj, data) => {
  const newObj = { ...obj }

  for (let k in newObj) {
    if (data[k] !== undefined) {
      newObj[k] = data[k]
    }
  }

  return newObj
}

export const preparePhone = phone => (phone ? phone.replace(/\D/g, '') : '')

export const phoneRegex = phone => {
  const numPhone = preparePhone(phone)

  if (numPhone.length !== 11) {
    return ''
  }
  const m = numPhone.match(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/)

  return `+${m[1]} (${m[2]}) ${m[3]}-${m[4]}-${m[5]}`
}

let timeout

export const debounce = (fn, wait = 500) => {
  if (timeout) {
    clearTimeout(timeout)
  }

  timeout = setTimeout(() => {
    fn()
    clearTimeout(timeout)
  }, wait)
}

export const toChunks = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size),
  )

export const preparePagination = headers => ({
  currentPage: parseInt(headers['x-pagination-current-page'], 10) || 1,
  pageCount: parseInt(headers['x-pagination-page-count'], 10) || 1,
  perPage: parseInt(headers['x-pagination-per-page'], 10) || 10,
  totalCount: parseInt(headers['x-pagination-total-count'], 10) || 0,
})

export const getActiveStatus = statuses => {
  let prepareStatuses = ''

  for (let key in statuses) {
    if (statuses[key]) {
      prepareStatuses += prepareStatuses ? ',' : ''
      prepareStatuses += `${key}`
    }
  }

  return prepareStatuses
}

export const downloadRegistry = ({
  path = '',
  blank = 0,
  download = false,
}) => {
  const link = document.createElement('a')

  link.href = path
  if (download) {
    link.setAttribute('download', '')
  }

  if (blank) {
    link.setAttribute('target', '_blank')
  }
  document.body.appendChild(link)
  link.click()

  link.remove()
}

export const byField = field => (a, b) => a[field] < b[field] ? 1 : -1
export const createFilter = queryString => link =>
  link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0

export const getArrRange = (array, range, part) => {
  let start = range * (part - 1)
  let end = range * part

  return array.slice(start, end)
}
