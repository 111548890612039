import * as actionsCommon from '@/store/actions'

import * as actions from '../actions'
import { GET_PROFILE_FROM_STATE } from '@/views/profile/store/actions'
import Api from '@/api'

export default {
  state: {
    userExporters: [],
    isLoading: false,
  },
  getters: {
    [actions.GET_USER_EXPORTERS]: state => state.userExporters,
    [actions.GET_IS_LOADING_USER_EXPORTERS]: state => state.isLoading,
  },
  actions: {
    [actions.FETCH_USER_EXPORTERS]({ commit, rootGetters }) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        user_id: rootGetters[GET_PROFILE_FROM_STATE].id,
      }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_USER_EXPORTERS, true)

        Api.control.exporters
          .getUserExporters(requestData)
          .then(({ data }) => commit(actions.SET_USER_EXPORTERS, data))
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_USER_EXPORTERS, false))
      }
    },
  },
  mutations: {
    [actions.SET_IS_LOADING_USER_EXPORTERS](state, payload) {
      state.isLoading = payload
    },
    [actions.SET_USER_EXPORTERS](state, data) {
      state.userExporters = data
    },
  },
}
