export const GET_PROFILE_FROM_STATE = '[PROFILE] GET_PROFILE_FROM_STATE'
export const GET_USERS_FROM_STATE = '[PROFILE] GET_USERS_FROM_STATE'
export const GET_IS_LOADING_USERS = '[PROFILE] GET_IS_LOADING_USERS'
export const GET_USERS_PAGINATION = '[PROFILE] GET_USERS_PAGINATION'

export const FETCH_USERS = '[PROFILE] FETCH_USERS'
export const CHANGE_USERS_REQUEST_FILTER_PARAMS =
  '[PROFILE] CHANGE_USERS_REQUEST_FILTER_PARAMS'
export const SET_USERS_REQUEST_FILTER_PARAMS =
  '[PROFILE] CHANGE_USERS_REQUEST_FILTER_PARAMS'
export const CHANGE_USERS_PAGINATION_PER_PAGE =
  '[PROFILE] CHANGE_USERS_PAGINATION_PER_PAGE'
export const CHANGE_USERS_PAGINATION_NEW_PAGE =
  '[PROFILE] CHANGE_USERS_PAGINATION_NEW_PAGE'
export const POST_USER = '[PROFILE] POST_USER'
export const DELETE_USERS = '[PROFILE] DELETE_USERS'
export const UPDATE_USERS = '[PROFILE] UPDATE_USERS'
export const FETCH_PROFILE = '[PROFILE] FETCH_PROFILE'
export const UPDATE_PROFILE = '[PROFILE] UPDATE_PROFILE'

export const SET_IS_LOADING_PROFILE = '[PROFILE] SET_IS_LOADING_PROFILE'
export const SET_USERS = '[PROFILE] SET_USERS'
export const SET_USERS_PAGINATION = '[PROFILE] SET_USERS_PAGINATION'
export const SET_USERS_PAGINATION_PER_PAGE =
  '[PROFILE] SET_USERS_PAGINATION_PER_PAGE'
export const SET_USERS_PAGINATION_CURRENT_PAGE =
  '[PROFILE] SET_USERS_PAGINATION_CURRENT_PAGE'
export const UNSHIFT_USERS = '[PROFILE] UNSHIFT_USERS'
export const SET_UPDATED_USERS = '[PROFILE] SET_UPDATED_USERS'
export const SET_PROFILE = '[PROFILE] SET_UPDATED_PROFILE'
