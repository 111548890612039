export const GET_QUOTAS = '[home/getter] GET_QUOTAS'
export const GET_IS_LOADING_QUOTAS = '[home/getter] GET_IS_LOADING_QUOTAS'
export const GET_TOTAL_PARKED = '[home/getter] GET_TOTAL_PARKED'
export const GET_MANUALS_INFO = '[home/getter] GET_MANUALS_INFO'
export const ADD_QUOTA = '[home/action] ADD_QUOTA'
export const UPDATE_EXPORTER_QUOTA = '[home/action] UPDATE_EXPORTER_QUOTA'
export const DELETE_QUOTA = '[home/action] DELETE_QUOTA'
export const FETCH_LIMIT_QUOTAS = '[home/action] FETCH_LIMIT_QUOTAS'
export const SET_QUOTAS_LIST = '[home/mutation] SET_QUOTAS_LIST'
export const SET_IS_LOADING_QUOTAS = '[home/mutation] SET_IS_LOADING_QUOTAS'
