import { Request, getParamsUrl } from '@/core'
import { quota } from '@/constants/urls'

export default {
  get: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${quota.list}${paramsFormatted}`)
  },
  addQuota: data => Request.post(quota.addQuota, data),
  getIndex: params =>
    Request.get(`${quota.list}${getParamsUrl(params)}&per-page=999`),
  deleteQuota: data =>
    Request.delete(`${quota.deleteQuota}${getParamsUrl(data)}`, { data }),
  parkingStats: id =>
    Request.get(`${quota.parkingCulturesStat}?unload_id=${id}`),

  // квоты экспортера
  updateExporterQuota: data => Request.post(quota.updateExporterQuota, data),
  saveSupplierQuota: data => Request.post(quota.saveSupplierQuota, data),
  deleteSupplierQuota: id => Request.post(quota.deleteSupplierQuota, { id }),
}
