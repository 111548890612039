export const GET_TIMESLOTS_FROM_STATE =
  '[Manager/getter] GET_TIMESLOTS_FROM_STATE'
export const GET_COMMON_SUPPLIERS_CHECK_INN =
  '[Manager/getter] GET_COMMON_SUPPLIERS_CHECK_INN'
export const GET_TIMESLOTS_STATISTIC_FROM_STATE =
  '[Manager/getter] GET_TIMESLOTS_STATISTIC_FROM_STATE'
export const GET_IS_LOADING_TIMESLOTS =
  '[Manager/getter] GET_IS_LOADING_TIMESLOTS'
export const GET_IS_LOADING_POST_TIMESLOTS =
  '[Manager/getter] GET_IS_LOADING_POST_TIMESLOTS'
export const GET_IS_LOADING_CALL_DRIVER =
  '[Manager/getter] GET_IS_LOADING_CALL_DRIVER'
export const GET_MANUALS_INFO = '[Manager/getter] GET_MANUALS_INFO'
export const GET_TIMESLOTS_PAGINATION =
  '[Manager/getter] GET_TIMESLOTS_PAGINATION'
export const GET_TIMESLOTS_CURRENT_STEP =
  '[Manager/getter] GET_TIMESLOTS_CURRENT_STEP'
export const GET_TERMINAL_QUOTA_FILTER =
  '[Manager/getter] GET_TERMINAL_QUOTA_FILTER'
export const GET_ACTIVE_TABLE_COLUMN =
  '[Manager/getter] GET_ACTIVE_TABLE_COLUMN'
export const GET_LOGS_FROM_STATE = '[TimeSlotDetail/getter] GET_LOGS_FROM_STATE'
export const GET_LOGS_IS_LOADING = '[TimeSlotDetail/getter] GET_LOGS_IS_LOADING'

export const FETCH_TIMESLOTS = '[Manager/action] FETCH_TIMESLOTS'
export const FETCH_COMMON_SUPPLIERS_CHECK_INN =
  '[Manager/action] FETCH_COMMON_SUPPLIERS_CHECK_INN'
export const FETCH_MANAGER_EXCEL_REPORT =
  '[Manager/action] FETCH_MANAGER_EXCEL_REPORT'
export const CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS =
  '[Manager/action] CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS'
export const CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS =
  '[Manager/action] CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS'
export const CHANGE_TIMESLOTS_PAGINATION_PER_PAGE =
  '[Manager/action] CHANGE_TIMESLOTS_PAGINATION_PER_PAGE'
export const CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE =
  '[Manager/action] CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE'
export const FETCH_TIMESLOT = '[Manager/action] FETCH_TIMESLOT'
export const FORCE_ARRIVAL_TIMESLOT = '[Manager/action] FORCE_ARRIVAL_TIMESLOT'
export const CONFIRM_TIMESLOT = '[Manager/action] CONFIRM_TIMESLOT'
export const POST_TIMESLOT = '[Manager/action] POST_TIMESLOT'
export const POST_HAND_TIMESLOT = '[Manager/action] POST_HAND_TIMESLOT'
export const DELETE_TIMESLOT = '[Manager/action] DELETE_TIMESLOT'
export const UPDATE_TIMESLOT = '[Manager/action] UPDATE_TIMESLOT'
export const GET_LOGS = '[TimeSlotDetail/actions] GET_LOGS'

export const SET_IS_LOADING_TIMESLOTS =
  '[Manager/mutation] SET_IS_LOADING_TIMESLOTS'
export const SET_COMMON_SUPPLIERS_CHECK_INN =
  '[Manager/mutation] SET_COMMON_SUPPLIERS_CHECK_INN'
export const SET_IS_LOADING_POST_TIMESLOTS =
  '[Manager/mutation] SET_IS_LOADING_POST_TIMESLOTS'
export const SET_IS_LOADING_CALL_DRIVER =
  '[Manager/mutation] SET_IS_LOADING_CALL_DRIVER'
export const SET_MANUALS_INFO = '[Manager/mutation] SET_MANUALS_INFO'
export const SET_TIMESLOTS = '[Manager/mutation] SET_TIMESLOTS'
export const SET_TIMESLOTS_STATISTICS =
  '[Manager/mutation] SET_TIMESLOTS_STATISTICS'
export const SET_TIMESLOTS_PAGINATION =
  '[Manager/mutation] SET_TIMESLOTS_PAGINATION'
export const SET_ACTIVE_TABLE_COLUMN_ACTION =
  '[Manager/mutation] SET_ACTIVE_TABLE_COLUMN_ACTION'

export const SET_TIMESLOTS_REQUEST_FILTER_PARAMS =
  '[Manager/mutation] SET_TIMESLOTS_REQUEST_FILTER_PARAMS'
export const SET_TIMESLOTS_QUOTA_FILTER =
  '[Manager/mutation] SET_TIMESLOTS_QUOTA_FILTER'
export const SET_TIMESLOTS_REQUEST_SORT_PARAMS =
  '[Manager/mutation] SET_TIMESLOTS_REQUEST_SORT_PARAMS'
export const SET_TIMESLOTS_PAGINATION_PER_PAGE =
  '[Manager/mutation] SET_TIMESLOTS_PAGINATION_PER_PAGE'
export const SET_TIMESLOTS_PAGINATION_CURRENT_PAGE =
  '[Manager/mutation] SET_TIMESLOTS_PAGINATION_CURRENT_PAGE'
export const UNSHIFT_TIMESLOT = '[Manager/mutation] UNSHIFT_TIMESLOT'
export const SET_DELETED_TIMESLOT = '[Manager/mutation] SET_DELETED_TIMESLOT'
export const SET_UPDATED_TIMESLOT = '[Manager/mutation] SET_UPDATED_TIMESLOT'
export const SET_ACTIVE_TABLE_COLUMN_MUTATION =
  '[Manager/mutation] SET_ACTIVE_TABLE_COLUMN_MUTATION'
export const SET_TIMESLOT_CURRENT_STEP =
  '[Manager/mutation] SET_TIMESLOT_CURRENT_STEP'
export const SET_LOGS = '[TimeSlotDetail/actions] SET_LOGS'
export const SET_LOGS_IS_LOADING =
  '[TimeSlotDetail/actions] SET_LOGS_IS_LOADING'
