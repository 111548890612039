import { Message } from 'element-ui'

export const showMessageSuccess = (
  message,
  showClose = true,
  duration = 5000,
) => {
  Message.success({
    showClose,
    message,
    duration,
  })
}

export const showMessageError = (
  message,
  showClose = true,
  duration = 5000,
) => {
  Message.error({
    showClose,
    message,
    duration,
  })
}

export function showMessageList(data, type = 'error') {
  const messages = Array.isArray(data)
    ? data.map(item => `<p>${item.message}</p><br />`).join('')
    : data.message || 'Произошла ошибка'

  Message({
    showClose: true,
    type,
    duration: 5000,
    dangerouslyUseHTMLString: true,
    message: `<ul>${messages}</ul>`,
  })
}
