import * as actionsCommon from '@/store/actions'
import { Message } from 'element-ui'

import { DATE_FORMAT, DATE_FORMAT_HOURS } from '@/constants/date'
import {
  DELETE_SUPERVISOR_QUOTA,
  DELETE_SUPERVISOR_QUOTAS_LINE,
  DELETE_SUPERVISOR_SAMPLE,
  FETCH_SUPERVISOR_ARCHIVE,
  FETCH_SUPERVISOR_QUOTA,
  FETCH_SUPERVISOR_QUOTAS_LINES,
  FETCH_SUPERVISOR_SAMPLES,
  FETCH_SUPERVISOR_STATISTIC_TABLE,
  GET_DATE_INTERVAL_QUOTAS_SUPERVISOR,
  GET_HISTORY_QUOTAS_SUPERVISOR,
  GET_LOADINGS_CALL_DRIVER,
  GET_LOADINGS_SUPERVISOR_STATISTIC,
  GET_REQUEST_STATUS,
  GET_SUPERVISOR_ARCHIVE,
  GET_SUPERVISOR_QUOTAS_FOR_TABLE,
  GET_SUPERVISOR_QUOTAS_LINES,
  GET_SUPERVISOR_SAMPLES,
  GET_SUPERVISOR_STATISTIC_TABLE,
  POST_SUPERVISOR_QUOTA,
  POST_SUPERVISOR_QUOTAS_LINES,
  PUT_SUPERVISOR_QUOTA,
  SAVE_SUPERVISOR_SAMPLE,
  SET_HISTORY_SUPERVISOR_QUOTA,
  SET_HISTORY_SUPERVISOR_QUOTA_FROM_SERVER,
  SET_LOADINGS_CALL_DRIVER,
  SET_LOADINGS_SUPERVISOR_STATISTIC,
  SET_REQUEST_STATUS,
  SET_SUPERVISOR_ARCHIVE,
  SET_SUPERVISOR_QUOTAS_LINES,
  SET_SUPERVISOR_QUOTA_CHANGE_ROW_BARS,
  SET_SUPERVISOR_QUOTA_NEW_DAY,
  SET_SUPERVISOR_SAMPLES,
  SET_SUPERVISOR_STATISTIC_TABLE,
} from '@/store/actions'
import {
  convertQuotaDateToTz,
  getDateToUnix,
  getDefaultDateUnix,
  getHours,
  getMinutes,
  getTzTime,
  nowUnix,
  supervisorDate,
} from '@/core'
import { showMessageSuccess } from '@/UI/message'
import API from '@/api'

export default {
  state: {
    archive: [],
    coords: [],
    changeHistoryGant: [],
    supervisorSamples: [],
    supervisorStatistic: [],
    supervisorStatisticTable: [],
    preparedSupervisorQuotas: [],
    loadingsSupervisorStatistic: true,
    isLoadingCallDriver: false,
    dateStart: '',
    dateEnd: '',
    quotasLines: [],
    requestStatus: false,
    errorAddQuota: false,
  },
  getters: {
    [GET_REQUEST_STATUS]: state => state.requestStatus,
    [GET_SUPERVISOR_QUOTAS_LINES]: state => state.quotasLines,
    [GET_SUPERVISOR_QUOTAS_FOR_TABLE]: state => state.preparedSupervisorQuotas,
    [GET_SUPERVISOR_STATISTIC_TABLE]: state => state.supervisorStatisticTable,
    [GET_LOADINGS_SUPERVISOR_STATISTIC]: state =>
      state.loadingsSupervisorStatistic,
    [GET_LOADINGS_CALL_DRIVER]: state => state.isLoadingCallDriver,
    [GET_HISTORY_QUOTAS_SUPERVISOR]: state => state.changeHistoryGant,
    [GET_DATE_INTERVAL_QUOTAS_SUPERVISOR]: state => ({
      dateStart: state.dateStart,
      dateEnd: state.dateEnd,
    }),
    [GET_SUPERVISOR_SAMPLES]: state => state.supervisorSamples,
    [GET_SUPERVISOR_ARCHIVE]: state => state.archive,
  },
  actions: {
    [FETCH_SUPERVISOR_QUOTAS_LINES]: ({ commit, dispatch, rootGetters }) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      API.supervisor
        .getSupervisorQuotasLines({ unload_id })
        .then(({ data }) => commit(SET_SUPERVISOR_QUOTAS_LINES, data))
        .then(() => dispatch(FETCH_SUPERVISOR_QUOTA))
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false)
          }, 1000)
        })
    },
    [FETCH_SUPERVISOR_STATISTIC_TABLE]: ({ commit, rootGetters }) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      API.supervisor
        .getSupervisorStatisticTable({ unload_id })
        .then(({ data }) => commit(SET_SUPERVISOR_STATISTIC_TABLE, data))
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false)
          }, 1000)
        })
    },
    [FETCH_SUPERVISOR_QUOTA]: ({ commit, rootGetters }) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const currentDay = Math.round(nowUnix() / 1000)
      const requestData = {
        unload_id,
        occurs_after: currentDay,
        deleted: 0,
      }

      API.supervisor
        .getSupervisorQuotas(requestData)
        .then(({ data }) => {
          commit(SET_HISTORY_SUPERVISOR_QUOTA_FROM_SERVER, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },
    [POST_SUPERVISOR_QUOTA]: (
      { commit, dispatch, rootGetters },
      requestData,
    ) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      requestData.unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      API.supervisor
        .postSupervisorQuotas(requestData)
        .then(() => {
          commit(SET_REQUEST_STATUS, false)
          dispatch(FETCH_SUPERVISOR_QUOTAS_LINES)
          Message({
            message: 'Правило вызова добавлено',
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },
    [POST_SUPERVISOR_QUOTAS_LINES]: ({ commit, dispatch, rootGetters }) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)

      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      API.supervisor
        .addSupervisorQuotasLine({ unload_id })
        .then(() => {
          dispatch(FETCH_SUPERVISOR_QUOTAS_LINES)
          Message({
            message: 'Линия добавлена',
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },

    [DELETE_SUPERVISOR_QUOTAS_LINE]: ({ commit, dispatch }, data) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)

      API.supervisor
        .deleteSupervisorQuotasLine(data)
        .then(() => {
          dispatch(FETCH_SUPERVISOR_QUOTAS_LINES)
          Message({
            message: 'Линия удалена',
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },
    [PUT_SUPERVISOR_QUOTA]: ({ commit, dispatch, rootGetters }, data) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      let requestData = []

      if (Array.isArray(data)) {
        requestData = data.map(item => ({ ...item, unload_id }))
      } else {
        requestData = [data]
      }

      API.supervisor
        .putSupervisorQuotas(requestData)
        .then(() => {
          Message({
            message: `Изменено`,
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => {
          dispatch(FETCH_SUPERVISOR_QUOTAS_LINES)
          setTimeout(() => {
            commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false)
          }, 1000)
        })
    },
    [DELETE_SUPERVISOR_QUOTA]: ({ commit, dispatch }, quotas) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)

      API.supervisor
        .deleteSupervisorQuotas(quotas)
        .then(() => {
          commit(SET_REQUEST_STATUS, false)
          dispatch(FETCH_SUPERVISOR_QUOTAS_LINES)
          Message({
            message: `Правило вызова удалено`,
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          console.log('error', error)
        })
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false)
          }, 1000)
        })
    },

    [FETCH_SUPERVISOR_SAMPLES]: ({ commit, rootGetters }) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id,
      }

      API.supervisor
        .getSupervisorSamples(requestData)
        .then(({ data }) => {
          commit(SET_SUPERVISOR_SAMPLES, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },

    [SAVE_SUPERVISOR_SAMPLE]({ commit, dispatch, rootGetters }, data) {
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        ...data,
        unload_id,
      }

      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)

      API.supervisor
        .saveSupervisorSample(requestData)
        .then(() => {
          dispatch(FETCH_SUPERVISOR_SAMPLES)
          showMessageSuccess('Шаблон добавлен')
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },

    [DELETE_SUPERVISOR_SAMPLE]: ({ commit, dispatch }, samples) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      API.supervisor
        .deleteSupervisorSample(samples)
        .then(() => {
          dispatch(FETCH_SUPERVISOR_SAMPLES)
          Message({
            message: `Шаблон удалён`,
            type: 'success',
            center: true,
          })
        })
        .catch(error => {
          Message({
            message: `Ошибка удаления шаблона -  ${error} `,
            type: 'error',
            center: true,
          })
          console.log('error', error)
        })
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false)
          }, 1000)
        })
    },

    [FETCH_SUPERVISOR_ARCHIVE]: ({ commit, rootGetters }, filters) => {
      commit(SET_LOADINGS_SUPERVISOR_STATISTIC, true)
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id,
        ...filters,
      }

      API.supervisor
        .getSupervisorArchive(requestData)
        .then(({ data }) => {
          commit(SET_SUPERVISOR_ARCHIVE, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_LOADINGS_SUPERVISOR_STATISTIC, false))
    },
  },

  mutations: {
    addCoords(state, payload) {
      state.coords.push(payload)
    },
    // .emptyState() is needed by VuexUndoRedo
    emptyState() {
      this.replaceState({ coords: [] })
    },
    [SET_REQUEST_STATUS]: (state, payload) => {
      state.requestStatus = payload
    },
    [SET_SUPERVISOR_QUOTAS_LINES]: (state, payload) => {
      state.quotasLines = payload
    },
    [SET_SUPERVISOR_STATISTIC_TABLE]: (state, payload) => {
      state.supervisorStatisticTable = payload
    },
    [SET_LOADINGS_SUPERVISOR_STATISTIC]: (state, payload) => {
      state.loadingsSupervisorStatistic = payload
    },
    [SET_LOADINGS_CALL_DRIVER]: (state, payload) => {
      state.isLoadingCallDriver = payload
    },
    [SET_HISTORY_SUPERVISOR_QUOTA_FROM_SERVER]: (state, payload) => {
      let dateStart = getDateToUnix(getTzTime(nowUnix(), DATE_FORMAT))
      let dateEnd = getDateToUnix(
        getTzTime(nowUnix() + 8.64e7 * 3, DATE_FORMAT),
      )
      const prepareRow = {}

      state.quotasLines.forEach(item => {
        prepareRow[item.id] = []
      })

      payload.forEach(item => {
        const payloadDateStart = getDefaultDateUnix(item.time_from)
        const payloadDateEnd = getDefaultDateUnix(item.time_to)

        item.time_from = convertQuotaDateToTz(item.time_from)
        item.time_to = convertQuotaDateToTz(item.time_to)

        dateStart = dateStart <= payloadDateStart ? dateStart : payloadDateStart
        dateEnd = dateEnd >= payloadDateEnd ? dateEnd : payloadDateEnd

        const keysRows = Object.keys(prepareRow)

        if (keysRows.includes(String(item.unload_line_id))) {
          prepareRow[item.unload_line_id].push(item)
        } else {
          prepareRow[item.unload_line_id] = [item]
        }
      })

      const rows = Object.keys(prepareRow).map(item => ({
        id: item,
        label: item,
        bars: prepareRow[item],
      }))

      state.changeHistoryGant = [JSON.parse(JSON.stringify(rows))]
      state.dateStart = getTzTime(dateStart, DATE_FORMAT)
      state.dateEnd = getFullDateEnd(dateEnd)
      // сформированные данные для табличного отображения
      state.preparedSupervisorQuotas = prepareTableItems(payload)
    },
    [SET_HISTORY_SUPERVISOR_QUOTA]: (state, payload) => {
      if (state.changeHistoryGant.length < 20) {
        state.changeHistoryGant.push(JSON.parse(JSON.stringify(payload)))
      } else {
        state.changeHistoryGant = state.changeHistoryGant.slice(-19)
        state.changeHistoryGant.push(JSON.parse(JSON.stringify(payload)))
      }
    },
    [SET_SUPERVISOR_QUOTA_NEW_DAY]: state => {
      state.dateEnd = getTzTime(
        getDateToUnix(getTzTime(state.dateEnd, DATE_FORMAT)) + 8.64e7,
        DATE_FORMAT_HOURS,
      )
    },
    [SET_SUPERVISOR_QUOTA_CHANGE_ROW_BARS]: state => {
      state.dateEnd = getTzTime(
        getDateToUnix(getTzTime(state.dateEnd, DATE_FORMAT)) + 8.64e7,
        DATE_FORMAT_HOURS,
      )
    },
    [SET_SUPERVISOR_SAMPLES]: (state, payload) => {
      if (payload.length) {
        const preparedSamples = payload.reduce((accum, item) => {
          let isNewItem = true

          accum = accum.map(accumItem => {
            if (
              accumItem[0].time_from === item.time_from &&
              accumItem[0].time_to === item.time_to
            ) {
              accumItem.push(item)
              isNewItem = false
            }

            return accumItem
          })

          if (isNewItem) {
            accum.push([item])
          }

          return accum
        }, [])

        if (preparedSamples.length > 1) {
          preparedSamples.sort((a, b) => {
            if (a[0].time_from < b[0].time_from) {
              return -1
            }
            if (a[0].time_from > b[0].time_from) {
              return 1
            }

            return 0
          })
        }

        preparedSamples.forEach(item => {
          if (item[0].time_to === '23:59') {
            item[0].time_to = '24:00'
          }
        })

        state.supervisorSamples = preparedSamples
      } else {
        state.supervisorSamples = []
      }
    },
    [SET_SUPERVISOR_ARCHIVE]: (state, payload) => {
      state.archive = prepareTableItems(payload)
    },
  },
}

function getFullDateEnd(date) {
  if (getHours(date) > 0 || getMinutes(date) > 0) {
    return getTzTime(
      getDateToUnix(getTzTime(date, DATE_FORMAT)) + 8.64e7,
      DATE_FORMAT_HOURS,
    )
  }

  return getTzTime(date, DATE_FORMAT_HOURS)
}

function prepareTableItems(tableData) {
  // формируем массив для отображения правил в формате таблицы
  const preparedQuotas = tableData.reduce((accum, item) => {
    const keys = Object.keys(accum)
    const currentDay = supervisorDate(item.time_from, DATE_FORMAT)

    // перевожу секунды в милисекунды для дальнейшей корректной работы с таймстампами
    item.time_from = Number(supervisorDate(item.time_from, 'x'))
    item.time_to = Number(supervisorDate(item.time_to, 'x'))
    let isNewItem = true

    if (!keys.includes(currentDay.toString())) {
      accum[currentDay] = [[item]]
    } else {
      accum[currentDay] = accum[currentDay].map(accumItem => {
        if (Array.isArray(accumItem)) {
          if (
            accumItem[0].time_from === item.time_from &&
            accumItem[0].time_to === item.time_to
          ) {
            accumItem.push(item)
            isNewItem = false
          }
        } else if (
          accumItem.time_from === item.time_from &&
          accumItem.time_to === item.time_to
        ) {
          accumItem = [accumItem, item]
          isNewItem = false
        }

        return accumItem
      })

      if (isNewItem) {
        accum[currentDay].push([item])
      }
    }

    return accum
  }, {})

  for (let key in preparedQuotas) {
    if (preparedQuotas[key].length > 1) {
      preparedQuotas[key].sort((a, b) => {
        if (a[0].time_from < b[0].time_from) {
          return -1
        }
        if (a[0].time_from > b[0].time_from) {
          return 1
        }

        return 0
      })
    }
  }

  return preparedQuotas
}
