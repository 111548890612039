export const MILLISECOND_IN_DAY = 1000 * 60 * 60 * 24
export const MILLISECOND_IN_2_HOURS = 1000 * 60 * 60 * 2
export const MINUTES_IN_DAY = 60 * 24
export const GET_HOUR = 'HH'
export const GET_TIME = 'HH:mm'
export const GET_DAY = 'DD.MM'
export const GET_YEAR = 'YYYY'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_HOURS = 'YYYY-MM-DD HH:mm'
export const DATE_FORMAT_WITHOUT_MINUTES = 'YYYY-MM-DD HH'
export const DATE_FORMAT_RU = 'DD.MM.YYYY'
export const DATE_FORMAT_FULL = 'YYYY-MM-DD HH:mm:ss'
export const DATE_FORMAT_FULL_RU = 'DD.MM.YYYY HH:mm'
export const DATE_FORMAT_SHORT = 'DD.MM HH:mm'
export const DATE_FORMAT_SHORT_REVERS = 'HH:mm DD.MM'
export const GET_TIME_FULL = 'HH:mm:ss'
export const WEEKDAY = 'dddd'

export const TOKEN_REQUEST_TIME = 60 * 1000
export const MILLISECOND_IN_MINUTE = 1000 * 60
