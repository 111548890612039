import { Request, getParamsUrl } from '@/core'
import { control } from '@/constants/urls'

export default {
  get: params => Request.get(`${control.exporters}${getParamsUrl(params)}`),
  checkInn: params =>
    Request.get(`${control.suppliersCheck}${getParamsUrl(params)}`),
  create: data => Request.post(control.exportersCreate, data),
  update: data => Request.put(`${control.exportersUpdate}?id=${data.id}`, data),
  delete: ids => Request.delete(control.exportersDelete, { data: { ids } }),

  checkUser: data =>
    Request.get(`${control.checkUserByPhone}${getParamsUrl(data)}`),

  getUserExporters: data =>
    Request.get(`${control.userExporters}${getParamsUrl(data)}`),
}
