import Blacklist from '@/views/control/store/modules/blacklist'
import Cultures from '@/views/control/store/modules/cultures'
import Exporters from '@/views/control/store/modules/exporters'
import Whitelist from '@/views/control/store/modules/whitelist'

export default {
  modules: {
    Cultures,
    Exporters,
    Blacklist,
    Whitelist,
  },
}
