import {
  FETCH_TABLE_STATIC_DATA,
  FETCH_UNLOADS_LIST,
  GET_LOADINGS_GET_UNLOADS_LIST,
  GET_LOADINGS_TABLE_STATIC_DATA,
  GET_TABLE_STATIC_DATA,
  GET_UNLOADS_LIST,
  GET_UNLOAD_ID,
  SET_LOADINGS_TABLE_STATIC_DATA,
  SET_LOADINGS_UNLOADS_LIST,
  SET_TABLE_STATIC_DATA,
  SET_UNLOADS_LIST,
  SET_UNLOAD_ID,
} from '@/store/actions'
import API from '@/api'

export default {
  state: {
    unload_id: JSON.parse(localStorage.getItem('unload_id')) || null,
    tableStaticData: [],
    unloadsList: [],
    loadingsTableStaticData: true,
    loadingsUnloadsList: true,
  },
  getters: {
    [GET_UNLOAD_ID]: state => state.unload_id,
    [GET_TABLE_STATIC_DATA]: state => state.tableStaticData,
    [GET_UNLOADS_LIST]: state => state.unloadsList,
    [GET_LOADINGS_TABLE_STATIC_DATA]: state => state.loadingsTableStaticData,
    [GET_LOADINGS_GET_UNLOADS_LIST]: state => state.loadingsUnloadsList,
  },
  actions: {
    [FETCH_TABLE_STATIC_DATA]: ({ commit }, unload_id) => {
      commit(SET_LOADINGS_TABLE_STATIC_DATA, true)

      API.publicStatic
        .getStatic({ unload_id })
        .then(({ data }) => {
          commit(SET_TABLE_STATIC_DATA, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_TABLE_STATIC_DATA, false)
          }, 1000)
        })
    },
    [FETCH_UNLOADS_LIST]: ({ commit }) => {
      commit(SET_LOADINGS_UNLOADS_LIST, true)

      API.publicStatic
        .getUnloads()
        .then(({ data }) => {
          commit(SET_UNLOADS_LIST, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(SET_LOADINGS_UNLOADS_LIST, false)
          }, 1000)
        })
    },
    // [UPDATE_UNLOAD_LIST]: ({ commit, rootGetters }, value) => {
    //   commit(SET_UNLOAD_ID, value)
    //   localStorage.setItem('unload_id', value)
    // },
  },
  mutations: {
    [SET_UNLOAD_ID]: (state, payload) => {
      state.unload_id = payload
      localStorage.setItem('unload_id', JSON.stringify(payload))
    },
    [SET_TABLE_STATIC_DATA]: (state, payload) => {
      state.tableStaticData = payload
    },
    [SET_LOADINGS_TABLE_STATIC_DATA]: (state, payload) => {
      state.loadingsTableStaticData = payload
    },
    [SET_LOADINGS_UNLOADS_LIST]: (state, payload) => {
      state.loadingsUnloadsList = payload
    },
    [SET_UNLOADS_LIST]: (state, payload) => {
      state.unloadsList = payload
    },
  },
}
