import * as actionsCommon from '@/store/actions'
import API from '@/api'

import * as actions from '../actions'
import { arrToObj, preparePagination } from '@/core'
import { initialPagination } from '@/constants/pagination'
import { showMessageSuccess } from '@/UI/message'

export default {
  state: {
    isLoading: false,
    suppliersPage: [],
    suppliersAll: [],
    suppliersForExporter: [],
    preparedSuppliers: {},
    pagination: initialPagination,
  },
  getters: {
    [actions.GET_SUPPLIERS_PAGE]: state => state.suppliersPage,
    [actions.GET_SUPPLIERS_ALL]: state => state.suppliersAll,
    [actions.GET_SUPPLIERS_FOR_EXPORTER]: state => state.suppliersForExporter,
    [actions.GET_SUPPLIERS_PAGINATION]: state => state.pagination,
    [actions.GET_IS_LOADING_SUPPLIERS]: state => state.isLoading,
  },
  actions: {
    [actions.FETCH_SUPPLIERS_ALL]({ commit, rootGetters }, data = {}) {
      const terminalCurrentId = data.id
        ? data.id
        : rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        unload_id: terminalCurrentId,
        page: 1,
        'per-page': 999,
      }

      // если запрос только поставщикам экспортера, к которым привязан пользователь
      if (data.full_list === 0) {
        requestData.full_list = 0
      }
      requestData.exporter_id = data.exporter_id

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_SUPPLIERS, true)

        API.control.suppliers
          .get(requestData)
          .then(({ data }) => commit(actions.SET_SUPPLIERS_ALL, data))
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_SUPPLIERS, false))
      }
    },
    [actions.FETCH_SUPPLIERS_FOR_EXPORTER](
      { commit, rootGetters },
      { exporterId, callback = () => {} },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        exporter_id: exporterId,
        page: 1,
        'per-page': 999,
      }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_SUPPLIERS, true)

        API.control.suppliers
          .get(requestData)
          .then(({ data }) => {
            commit(actions.SET_SUPPLIERS_FOR_EXPORTER, data)
            callback()
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_SUPPLIERS, false))
      }
    },
    [actions.FETCH_SUPPLIERS_PAGE]({ commit, rootGetters }, payload) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        ...payload,
      }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_SUPPLIERS, true)

        API.control.suppliers
          .get(requestData)
          .then(({ headers, data }) =>
            commit(actions.SET_SUPPLIERS_PAGE, {
              data,
              pagination: preparePagination(headers),
            }),
          )
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_SUPPLIERS, false))
      }
    },
    [actions.SAVE_SUPPLIER](
      { commit, dispatch, rootGetters },
      { requestData, callback, params },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID]
      const data = { unload_id: terminalCurrentId, ...requestData }

      // если пришел ИД - обновляем, если нет - создаем нового
      const method = requestData.id ? 'put' : 'post'

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_SUPPLIERS, true)

        API.control.suppliers[method](data)
          .then(() => {
            dispatch(actions.FETCH_SUPPLIERS_PAGE, params)
            dispatch(actions.FETCH_SUPPLIERS_ALL)
            showMessageSuccess(
              requestData.id
                ? 'Поставщик успешно изменен'
                : 'Поставщик успешно создан',
            )
            callback()
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_SUPPLIERS, false))
      }
    },
    [actions.DELETE_SUPPLIERS](
      { commit, dispatch },
      { ids, callback, params },
    ) {
      commit(actions.SET_IS_LOADING_SUPPLIERS, true)

      API.control.suppliers
        .delete(ids)
        .then(() => {
          dispatch(actions.FETCH_SUPPLIERS_PAGE, params)
          dispatch(actions.FETCH_SUPPLIERS_ALL)
          showMessageSuccess(
            ids.length > 1 ? 'Поставщики были удалены' : 'Поставщик был удален',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_SUPPLIERS, false))
    },
  },
  mutations: {
    [actions.SET_IS_LOADING_SUPPLIERS](state, payload) {
      state.isLoading = payload
    },
    [actions.SET_SUPPLIERS_PAGE](state, { data, pagination }) {
      state.suppliersPage = data
      state.pagination = pagination
    },
    [actions.SET_SUPPLIERS_ALL](state, payload) {
      state.suppliersAll = payload
    },
    [actions.SET_SUPPLIERS_FOR_EXPORTER](state, payload) {
      state.suppliersForExporter = payload
    },
  },
}
