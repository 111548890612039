import axios from 'axios'

import * as Sentry from '@sentry/vue'
import { ENV } from '@/constants/env'
import { SET_LOGOUT } from '@/store/actions'
import { getLoginToken, removeLoginParams } from '@/core'
import { showMessageError, showMessageList } from '@/UI/message'
import router from '@/router'
import store from '@/store'

export const getParamsUrl = settings => {
  let params = ''

  for (let key in settings) {
    if (Boolean(settings[key]) || settings[key] === 0) {
      params += params ? '&' : '?'
      params += `${key}=${settings[key]}`
    }
  }

  return params
}

const Request = axios.create({ baseURL: ENV.BASE_API_URL, timeout: 60000 })

Request.interceptors.request.use(
  config => {
    const token = getLoginToken()
    const newConfig = config

    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`
    }

    return newConfig
  },
  error => {
    if (!(error instanceof Error) && typeof error?.message === 'string') {
      Sentry.captureMessage(error.message)
    } else {
      Sentry.captureException(error)
    }

    return Promise.reject(error)
  },
)

Request.interceptors.response.use(
  res => res,
  async error => {
    if (422 === error.response?.status) {
      showMessageList(error.response.data)

      return Promise.reject(error)
    }

    if (429 === error.response?.status) {
      showMessageError(
        'Превышен лимит запросов. Попробуйте загрузить страницу позднее',
      )

      return Promise.reject(error)
    }

    if (401 === error.response?.status) {
      await removeLoginParams()
      await store.commit(SET_LOGOUT)

      if (typeof window?.location?.reload === 'function') {
        window.location.reload()
      } else if (typeof document?.location?.reload === 'function') {
        document.location.reload(true)
      } else {
        await router.push('/login')
      }
    } else if (
      !(error instanceof Error) &&
      typeof error?.message === 'string'
    ) {
      Sentry.captureMessage(error?.message)
    } else {
      Sentry.captureException(error)
    }

    return Promise.reject(error.response)
  },
)

export { Request }
