import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import Vue from 'vue'
import router from '@/router'

const {
  VUE_APP_SENTRY_ID,
  VUE_APP_SENTRY_NAME,
  NODE_ENV,
  VUE_APP_URL_PROD,
  VUE_APP_LS_KEY,
  VUE_APP_VERSION,
} = process.env

if (NODE_ENV === 'production') {
  Sentry.init({
    Vue,
    dsn: `https://${VUE_APP_SENTRY_ID}@o327728.ingest.sentry.io/${VUE_APP_SENTRY_NAME}`,
    release: `${VUE_APP_LS_KEY}@${VUE_APP_VERSION}`,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', VUE_APP_URL_PROD, /^\//],
      }),
    ],
    ignoreErrors: [
      'timeout of 60000ms exceeded',
      'ResizeObserver loop limit exceeded',
      'Network Error',
      'close',
      'cancel',
    ],
  })
}
