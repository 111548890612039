import * as actionsCommon from '@/store/actions'
import {
  ADD_QUOTA,
  DELETE_QUOTA,
  FETCH_LIMIT_QUOTAS,
  GET_IS_LOADING_QUOTAS,
  GET_MANUALS_INFO,
  GET_QUOTAS,
  GET_TOTAL_PARKED,
  SET_IS_LOADING_QUOTAS,
  SET_QUOTAS_LIST,
  UPDATE_EXPORTER_QUOTA,
} from '@/views/home/store/actions'
import { DATE_FORMAT } from '@/constants/date'
import { getDateTzFromUnix } from '@/core'
import { showMessageSuccess } from '@/UI/message'
import API from '@/api'

export default {
  state: {
    quotas: {},
    isLoading: false,
    totalParked: 0,
    manuals_info: {
      manuals_enabled: 0,
      manuals_limit: 0,
      manuals_used: 0,
    },
  },
  getters: {
    [GET_QUOTAS]: state => state.quotas,
    [GET_IS_LOADING_QUOTAS]: state => state.isLoading,
    [GET_TOTAL_PARKED]: state => state.totalParked,
    [GET_MANUALS_INFO]: state => state.manuals_info,
  },
  actions: {
    [FETCH_LIMIT_QUOTAS]({ commit, rootGetters }, exporter_id = undefined) {
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      if (unload_id) {
        commit(SET_IS_LOADING_QUOTAS, true)

        API.quota
          .getIndex({ unload_id, exporter_id })
          .then(({ data }) => commit(SET_QUOTAS_LIST, data))
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_QUOTAS, false))
      }
    },
    [ADD_QUOTA](
      { commit, dispatch, rootGetters },
      { data, callback = () => {} },
    ) {
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        ...data,
        unload_id,
      }

      if (unload_id) {
        commit(SET_IS_LOADING_QUOTAS, true)

        API.quota
          .addQuota(requestData)
          .then(async ({ data }) => {
            // в случае необходимости принудительно распределяем все ТС в общую квоту
            // (если не нужно - callback приходит пустой функцией)
            await callback(data.id, data.trucks_allowed_count)
            dispatch(FETCH_LIMIT_QUOTAS)
            showMessageSuccess('Квота добавлена')
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_QUOTAS, false))
      }
    },

    [UPDATE_EXPORTER_QUOTA]({ commit, dispatch }, data) {
      commit(SET_IS_LOADING_QUOTAS, true)

      API.quota
        .updateExporterQuota(data)
        .then(() => showMessageSuccess('Квота обновлена'))
        .catch(error => console.log('error', error))
        .finally(() => {
          dispatch(FETCH_LIMIT_QUOTAS)
          commit(SET_IS_LOADING_QUOTAS, false)
        })
    },

    [DELETE_QUOTA]({ commit, dispatch }, data) {
      commit(SET_IS_LOADING_QUOTAS, true)

      API.quota
        .deleteQuota(data)
        .then(() => dispatch(FETCH_LIMIT_QUOTAS))
        .then(() => showMessageSuccess('Квота удалена'))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_QUOTAS, false))
    },
  },
  mutations: {
    [SET_QUOTAS_LIST]: (state, payload) => {
      const prepareQuotas = payload.quotas.reduce((accum, item) => {
        const keys = Object.keys(accum)
        const currentDay = getDateTzFromUnix(item.time_from, DATE_FORMAT)

        // перевожу секунды в милисекунды для дальнейшей корректной работы с таймстампами
        item.time_from = item.time_from * 1000
        item.time_to = item.time_to * 1000
        let isNewItem = true

        if (!keys.includes(currentDay.toString())) {
          accum[currentDay] = [[item]]
        } else {
          accum[currentDay] = accum[currentDay].map(accumItem => {
            if (Array.isArray(accumItem)) {
              if (
                accumItem[0].time_from === item.time_from &&
                accumItem[0].time_to === item.time_to
              ) {
                accumItem.push(item)
                isNewItem = false
              }
            } else if (
              accumItem.time_from === item.time_from &&
              accumItem.time_to === item.time_to
            ) {
              accumItem = [accumItem, item]
              isNewItem = false
            }

            return accumItem
          })

          if (isNewItem) {
            accum[currentDay].push([item])
          }
        }

        return accum
      }, {})

      for (let key in prepareQuotas) {
        if (prepareQuotas[key].length > 1) {
          prepareQuotas[key].sort((a, b) => {
            if (a[0].time_from < b[0].time_from) {
              return -1
            }
            if (a[0].time_from > b[0].time_from) {
              return 1
            }

            return 0
          })
        }
      }
      state.quotas = prepareQuotas
      state.totalParked = payload.total_parked ? payload.total_parked : 0

      state.manuals_info = {
        manuals_enabled: payload.manuals_limit_enabled,
        manuals_limit: payload.manuals_limit,
        manuals_used: payload.manuals_for_today,
      }
    },
    [SET_IS_LOADING_QUOTAS]: (state, payload) => (state.isLoading = payload),
  },
}
