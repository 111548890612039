import { Message } from 'element-ui'

const loopHelper = data => {
  if (typeof data === 'string') {
    return data
  }
  if (data[0] && data[0].message) {
    return data[0].message
  }

  for (let key in data) {
    if (!Object.prototype.hasOwnProperty.call(data, key)) {
      continue
    }

    return typeof data[key] === 'string' ? data[key] : data[key][0]
  }
}

export const getError = (err, check = false) => {
  let { response, status, data } = err || {}

  if (response) {
    data = response.data
    status = response.status
  }
  let error = ''

  if ([400, 422, 429].includes(status) && data) {
    if (typeof data === 'string') {
      error = data
    } else if (data[0] && data[0].message) {
      error = loopHelper(data[0].message)
    } else if (data.errors) {
      error = loopHelper(data.errors)
    } else if (data.message) {
      error = loopHelper(data.message)
    }
  }

  if (error.includes('Другой код был отправлен недавно')) {
    error += '\nПодождите 2 минуты и нажмите получить код'
  }

  if (!error && check) {
    return ''
  }

  return error ? error : 'Неизвестная ошибка'
}

export const errHandlerHelper = err => {
  Message.closeAll()

  if (!err) {
    err = {}
  }
  const error = typeof err === 'string' ? err : getError(err)

  Message.error({
    showClose: true,
    message: error,
    duration: 5000,
  })

  return 'error'
}
