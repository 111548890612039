// SUPPLIERS - это поставщики экспортера

export const GET_SUPPLIERS_ALL = '[Suppliers/getter] GET_SUPPLIERS_ALL'
export const GET_SUPPLIERS_FOR_EXPORTER =
  '[Suppliers/getter] GET_SUPPLIERS_FOR_EXPORTER'
export const GET_SUPPLIERS_PAGE = '[Suppliers/getter] GET_SUPPLIERS_PAGE'
export const GET_SUPPLIERS_PAGINATION =
  '[Suppliers/getter] GET_SUPPLIERS_PAGINATION'
export const GET_IS_LOADING_SUPPLIERS =
  '[Suppliers/getter] GET_IS_LOADING_SUPPLIERS'

export const GET_USER_EXPORTERS = '[UserExporters/getter] GET_USER_EXPORTERS'
export const GET_IS_LOADING_USER_EXPORTERS =
  '[UserExporters/getter] GET_IS_LOADING_USER_EXPORTERS'

export const FETCH_SUPPLIERS_ALL = '[Suppliers/action] FETCH_SUPPLIERS'
export const FETCH_SUPPLIERS_FOR_EXPORTER =
  '[Suppliers/action] FETCH_SUPPLIERS_FOR_EXPORTER'
export const FETCH_SUPPLIERS_PAGE = '[Suppliers/action] FETCH_SUPPLIERS_PAGE'
export const FETCH_USER_EXPORTERS =
  '[UserExporters/action] FETCH_USER_EXPORTERS'
export const DELETE_SUPPLIERS = '[Suppliers/action] DELETE_SUPPLIERS'
export const SAVE_SUPPLIER = '[Suppliers/action] SAVE_SUPPLIER'

export const SET_IS_LOADING_SUPPLIERS =
  '[Suppliers/mutation] SET_IS_LOADING_SUPPLIERS'
export const SET_SUPPLIERS_PAGE = '[Suppliers/mutation] SET_SUPPLIERS_PAGE'
export const SET_SUPPLIERS_ALL = '[Suppliers/mutation] SET_SUPPLIERS_ALL'
export const SET_SUPPLIERS_FOR_EXPORTER =
  '[Suppliers/mutation] SET_SUPPLIERS_FOR_EXPORTER'
export const SET_USER_EXPORTERS = '[UserExporters/mutation] SET_USER_EXPORTERS'
export const SET_IS_LOADING_USER_EXPORTERS =
  '[UserExporters/mutation] SET_IS_LOADING_USER_EXPORTERS'
