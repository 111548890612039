import { Request, getParamsUrl } from '@/core'
import { control } from '@/constants/urls'

export default {
  get: params => Request.get(`${control.suppliersPage}${getParamsUrl(params)}`),
  checkInn: params =>
    Request.get(`${control.suppliersCheck}${getParamsUrl(params)}`),
  post: data => Request.post(control.suppliersCreate, data),
  put: data => Request.put(`${control.suppliersUpdate}?id=${data.id}`, data),
  delete: suppliers_ids =>
    Request.delete(control.suppliersDelete, { data: { suppliers_ids } }),

  postSupplierPhone: data => Request.post(control.suppliersAddPhones, data),
  putSupplierPhone: data =>
    Request.put(`${control.suppliersUpdatePhones}?id=${data.id}`, data),
  deleteSupplierPhone: data =>
    Request.delete(control.suppliersDeletePhones, { data }),
}
