import Vue from 'vue'
import Vuex from 'vuex'

import { ENV } from '@/constants/env'
import Auth from '@/store/modules/auth'
import Control from '@/views/control/store'
import Dialog from '@/store/modules/dialog'
import Drawer from '@/store/modules/drawer'
import Drivers from '@/store/modules/drivers'
import Exporter from '@/views/exporter/store'
import Home from '@/views/home/store'
import Manager from '@/views/manager/store/manager'
import Parkman from '@/views/parkman/store/parkman'
import Profile from '@/views/profile/store/profile'
import Samples from '@/views/quota-samples/store'
import ScreenSize from '@/store/modules/screenSize'
import Settings from '@/views/settings/store/settings'
import Static from '@/store/modules/static'
import Supervisor from '@/views/supervisor/store/supervisor'
import Terminal from '@/store/modules/terminal'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Settings,
    Manager,
    Parkman,
    Home,
    Auth,
    Dialog,
    Drawer,
    Control,
    Terminal,
    Drivers,
    Supervisor,
    Profile,
    Samples,
    Static,
    ScreenSize,
    Exporter,
  },
  strict: ENV.NODE_ENV !== 'production',
})
