const adminRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: { layout: 'Default', title: 'Квоты' },
    role: ['admin', 'viewer', 'superadmin'],
  },
  {
    path: '/samples',
    name: 'Samples',
    component: () => import('@/views/quota-samples/QuotaSamples.vue'),
    meta: { layout: 'Default', title: 'Шаблоны суточных квот' },
    role: ['admin', 'superadmin'],
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About.vue'),
    meta: { layout: 'Default', title: 'Помощь' },
    role: ['admin', 'superadmin'],
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/settings/Settings.vue'),
    meta: { layout: 'Default', title: 'Настройки' },
    role: ['admin', 'superadmin'],
  },
  {
    path: '/hourlimit',
    name: 'Hourlimit',
    component: () => import('@/views/hourlimit/Hourlimit.vue'),
    meta: { layout: 'Public', role: ['public'] },
    role: ['public'],
  },
  {
    path: '/payment-doc',
    name: 'PaymentDoc',
    component: () => import('@/views/payment/PaymentDoc.vue'),
    meta: { layout: 'Public' },
    role: ['public'],
  },
  {
    path: '/control',
    component: () => import('@/views/control/Control.vue'),
    meta: { layout: 'Default', title: 'Управление' },
    role: ['admin', 'viewer', 'superadmin'],
    children: [
      {
        path: '',
        name: 'Control-terminal',
        component: () => import('@/views/manager/Manager.vue'),
        meta: { layout: 'Default', title: 'Терминал' },
      },
      {
        path: 'culture',
        name: 'Control-Culture',
        component: () =>
          import(
            '@/views/control/components/tableCulture/ControlTableCulture.vue'
          ),
        meta: { layout: 'Default', title: 'Культуры' },
      },
      {
        path: 'exporters',
        name: 'Control-Exporters',
        component: () =>
          import(
            '@/views/control/components/tableExporters/ControlTableExporters'
          ),
        meta: { layout: 'Default', title: 'Экспортеры' },
      },
      {
        path: 'blacklist',
        name: 'Control-Blacklist',
        component: () =>
          import(
            '@/views/control/components/tableBlacklist/ControlTableBlacklist'
          ),
        meta: { layout: 'Default', title: 'Черный список' },
      },
      {
        path: 'whitelist',
        name: 'Control-Whitelist',
        component: () =>
          import(
            '@/views/control/components/tableWhitelist/ControlTableWhitelist'
          ),
        meta: { layout: 'Default', title: 'Транспорт терминала' },
      },
    ],
  },
  {
    path: '/admin-supervisor',
    name: 'AdminSupervisor',
    component: () => import('@/views/supervisor/SupervisorGant.vue'),
    meta: { layout: 'Default', title: 'Супервизор' },
    role: ['superadmin', 'admin'],
  },
  {
    path: '/admin-supervisor-table',
    name: 'AdminSupervisorTable',
    component: () => import('@/views/supervisor/SupervisorTable.vue'),
    meta: { layout: 'Default', title: 'Супервизор' },
    role: ['superadmin', 'admin'],
  },
  {
    path: '/admin-supervisor-archive',
    name: 'AdminSupervisorArchive',
    component: () => import('@/views/supervisor/SupervisorArchive.vue'),
    meta: { layout: 'Default', title: 'Супервизор' },
    role: ['superadmin', 'admin'],
  },
  {
    path: '/admin-supervisor-samples',
    name: 'AdminSupervisorSamples',
    component: () => import('@/views/supervisor/SupervisorSamples.vue'),
    meta: { layout: 'Default', title: 'Супервизор' },
    role: ['superadmin', 'admin'],
  },
  {
    path: '/profile',
    component: () => import('@/views/profile/Profile.vue'),
    meta: { layout: 'Default', title: 'Регистрация нового пользователя' },
    role: ['admin', 'superadmin'],
    children: [
      {
        path: '',
        name: 'Profile-settings',
        component: () =>
          import(
            '@/views/profile/components/profile-profile/ProfileSettings.vue'
          ),
        meta: { layout: 'Default', title: 'Профиль' },
      },
      {
        path: 'table',
        name: 'Profile-table',
        component: () =>
          import('@/views/profile/components/profile-table/ProfileTable.vue'),
        meta: { layout: 'Default', title: 'Таблица пользователей' },
      },
    ],
  },
  {
    path: '/ui',
    name: 'UI',
    component: () => import('@/views/ui/UI.vue'),
    meta: { layout: 'Default', title: 'UI компоненты' },
    role: ['admin', 'superadmin'],
  },
]

const exporterRoutes = [
  {
    path: '/exporter-quotas',
    name: 'ExporterQuotas',
    component: () =>
      import('@/views/exporter/exporter-quotas/ExporterQuotas.vue'),
    meta: {
      layout: 'Exporter',
      title: 'Квоты экспортера',
      role: ['exporter'],
    },
    role: ['exporter'],
  },
  {
    path: '/exporter-suppliers',
    name: 'ExporterSuppliers',
    component: () =>
      import('@/views/exporter/exporter-suppliers/ExporterSuppliers.vue'),
    meta: {
      layout: 'Exporter',
      title: 'Поставщики экспортера',
      role: ['exporter'],
    },
    role: ['exporter'],
  },
]

const driverRoutes = [
  {
    path: '/drivers',
    component: () => import('@/views/drivers-home/index.vue'),
    meta: {
      public: true,
      layout: 'Drivers',
      title: 'Водители',
    },
    role: ['driver', 'exporter'],
    children: [
      {
        name: 'GettingSlots',
        path: '',
        component: () => import('@/views/drivers-home/GettingSlots.vue'),
        meta: { layout: 'Drivers', title: 'Водители - получение тайм слотов' },
      },
      {
        name: 'ConfirmAddAuto',
        path: '/confirm-add-auto/:id',
        component: () =>
          import('@/views/drivers-home/components/ConfirmAddAuto.vue'),
        meta: { layout: 'Drivers', title: 'Водители - получение тайм слотов' },
      },
      {
        name: 'Timeslots',
        path: 'timeslot',
        component: () => import('@/views/drivers-home/Timeslots.vue'),
        meta: { layout: 'Drivers', title: 'Водители - тайм слоты' },
      },
    ],
  },
]

const terminalUI = [
  {
    path: '/terminal',
    name: 'terminal',
    component: () => import('@/views/terminalUI/authView/AuthView'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'auth',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/car-number',
    name: 'terminal-carNumber',
    component: () => import('@/views/terminalUI/autoView/AutoView'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'number of car',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/early-arrived',
    name: 'terminal-earlyArrived',
    component: () => import('@/views/terminalUI/earlyArrived/EarlyArrived'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'truck arrived earlier',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/intime-arrived',
    name: 'terminal-intimeArrived',
    component: () => import('@/views/terminalUI/intimeArrived/IntimeArrived'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'truck arrived in time',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/without-timeslot-arrived',
    name: 'terminal-withoutTimeslotArrived',
    component: () =>
      import(
        '@/views/terminalUI/withoutTimeslotArrived/WithoutTimeslotArrived'
      ),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: "truck hasn't timeslot",
    },
    role: ['driver'],
  },
  {
    path: '/terminal/hand-timeslot-form',
    name: 'terminal-handTimeslotForm',
    component: () =>
      import('@/views/terminalUI/handTimeslotForm/HandTimeslotForm'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'hand timeslot form',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/additional-fields-form',
    name: 'terminal-additionalFieldsForm',
    component: () =>
      import('@/views/terminalUI/handTimeslotForm/AdditionalFieldsForm'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'additional fields form',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/finally-response',
    name: 'terminal-finallyResponse',
    component: () =>
      import('@/views/terminalUI/finallyResponse/FinallyResponse'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'check-in complete',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/finally-hand-response',
    name: 'terminal-finallyHandResponse',
    component: () =>
      import('@/views/terminalUI/finallyHandResponse/FinallyHandResponse'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'hand check-in complete',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/limit-hand-response',
    name: 'terminal-limitHandResponse',
    component: () =>
      import('@/views/terminalUI/limitHandResponse/LimitHandResponse'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'hand check-in decline',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/no-nearest-quotas',
    name: 'terminal-noNearestQuotas',
    component: () =>
      import('@/views/terminalUI/noNearestQuotas/NoNearestQuotas'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'no quotas',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/current-queue',
    name: 'terminal-currentQueue',
    component: () => import('@/views/terminalUI/currentQueue/CurrentQueue.vue'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'queue current state',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/blacklist',
    name: 'terminal-blacklist',
    component: () =>
      import('@/views/terminalUI/truckInBlacklist/TruckInBlacklist.vue'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'truck in blacklist',
    },
    role: ['driver'],
  },
  {
    path: '/terminal/*',
    name: 'terminal-404',
    component: () => import('@/views/terminalUI/404/page404'),
    meta: {
      public: true,
      layout: 'TerminalUI',
      title: 'page not found',
    },
    role: ['driver'],
  },
]

const routes = [
  ...adminRoutes,
  ...exporterRoutes,
  ...driverRoutes,
  ...terminalUI,
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login.vue'),
    meta: {
      layout: 'Login',
      title: 'Вход',
    },
  },
  {
    path: '/manager',
    name: 'Manager',
    component: () => import('@/views/manager/Manager.vue'),
    meta: { layout: 'Other', title: 'Терминал' },
    role: ['manager', 'support'],
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: () =>
      import('@/views/control/components/tableBlacklist/ControlTableBlacklist'),
    meta: { layout: 'Other', title: 'Черный список' },
    role: ['support'],
  },
  {
    path: '/parkman',
    name: 'Parkman',
    component: () => import('@/views/parkman/Parkman.vue'),
    meta: { layout: 'Other', title: 'Паркмен' },
    role: ['parkman'],
  },
  {
    path: '/supervisor',
    name: 'Supervisor',
    component: () => import('@/views/supervisor/SupervisorGant.vue'),
    meta: { layout: 'Other', title: 'Супервизор' },
    role: ['supervisor', 'support'],
  },
  {
    path: '/supervisor-table',
    name: 'SupervisorTable',
    component: () => import('@/views/supervisor/SupervisorTable.vue'),
    meta: { layout: 'Other', title: 'Супервизор' },
    role: ['supervisor', 'support'],
  },
  {
    path: '/supervisor-archive',
    name: 'SupervisorArchive',
    component: () => import('@/views/supervisor/SupervisorArchive.vue'),
    meta: { layout: 'Other', title: 'Супервизор' },
    role: ['supervisor', 'support'],
  },
  {
    path: '/supervisor-samples',
    name: 'SupervisorSamples',
    component: () => import('@/views/supervisor/SupervisorSamples.vue'),
    meta: { layout: 'Other', title: 'Супервизор' },
    role: ['supervisor', 'support'],
  },
  {
    path: '/user-profile',
    name: 'UserProfile',
    component: () =>
      import('@/views/profile/components/profile-profile/ProfileSettings'),
    meta: { layout: 'Other', title: 'Профиль' },
    role: ['all'],
  },
  {
    path: '/quotas',
    name: 'HomeSupport',
    component: () => import('@/views/home/Home.vue'),
    meta: { layout: 'Other', title: 'Квоты' },
    role: ['support'],
  },
]

export default routes
