import {
  DR_CLEAN_FULL_FORM_WITH_AUTO,
  DR_FETCH_AUTOCOMLETE_DATA,
  DR_FETCH_AUTOCOMLETE_SUPPLIER,
  DR_FETCH_COMMON_SUPPLIERS_CHECK_INN,
  DR_FETCH_CULTURES,
  DR_FETCH_SUPPLIERS,
  DR_FETCH_TERMINALS,
  DR_FETCH_TIMESLOT_LIST,
  DR_FETCH_USER_PROFILE,
  DR_GET_AUTOCOMLETE_DATA,
  DR_GET_AUTOCOMLETE_SUPPLIER,
  DR_GET_CULTURES,
  DR_GET_CURRENT_TIME,
  DR_GET_FULL_FORM_WITH_AUTO,
  DR_GET_LOADINGS,
  DR_GET_RUN_TIMER,
  DR_GET_SETUP_TIME,
  DR_GET_SLOAT_PRICE_DATA,
  DR_GET_SUPPLIERS,
  DR_GET_TERMINALS,
  DR_GET_TIMESLOT_LIST,
  DR_GET_TIMESLOT_PAGINATION,
  DR_GET_UNLOAD_ID,
  DR_GET_USER_PROFILE,
  DR_POST_AUTOCOMPLETE_DATA,
  DR_POST_TIMESLOTS_SUBMIT,
  DR_POST_TIME_REQUEST,
  DR_REMOVE_TIMESLOT,
  DR_SET_AUTOCOMPLETE_DATA,
  DR_SET_AUTOCOMPLETE_SUPPLIER,
  DR_SET_CHOSEN_UNLOAD_ID,
  DR_SET_CULTURES,
  DR_SET_CURRENT_TIME,
  DR_SET_FULL_FORM_WITH_AUTO,
  DR_SET_LOADINGS,
  DR_SET_RUN_TIMER,
  DR_SET_SLOAT_PRICE_DATA,
  DR_SET_SUPPLIERS,
  DR_SET_TERMINALS,
  DR_SET_TIMESLOT_LIST,
  DR_SET_TIMESLOT_PAGINATION,
  DR_SET_USER_PROFILE,
  DR_UPDATE_CURRENT_TIME,
  DR_UPDATE_FULL_FORM_WITH_AUTO,
  DR_UPDATE_RUN_TIMER,
  DR_UPDATE_SETUP_TIME,
  DR_UPDATE_SLOAT_PRICE_DATA,
  DR_UPDATE_SLOAT_PRICE_DATA_STATUS,
  DR_UPDATE_TIMESLOTS_SUBMIT,
  DR_UPDATE_TIMESLOT_LIST,
} from '@/store/actions'

import * as actions from '../../views/control/store/actions'

import { Message } from 'element-ui'
import { TIMER_TIME_SLOTS } from '@/constants/time'
import { isArray } from 'lodash'
import { isObject } from 'element-ui/src/utils/types'
import { setTerminalId } from '@/core'
import API from '@/api'

export default {
  state: {
    userProfile: null,
    setupTime: localStorage.getItem('endTime'),
    cultures: null,
    terminals: null,
    suppliers: null,
    token: '1',
    loadings: false,
    slotPriceData: JSON.parse(localStorage.getItem('formPriceData')) || [],
    currentTime: TIMER_TIME_SLOTS,
    runTimer:
      JSON.parse(localStorage.getItem('formPriceData'))?.length > 0 || false,
    confirmDriverTimeslotList: [],
    confirmDriverTimeslotPagination: null,
    message: null,
    autocompleteData: [],
    autocompleteSupplier: [],
    unload_id: null,
    fullFormWithAuto:
      JSON.parse(localStorage.getItem('fullFormWithAuto')) || [],

    terminalCurrentIdSlug: null,
  },
  getters: {
    [DR_GET_CULTURES]: state => state.cultures,
    [DR_GET_USER_PROFILE]: state => state.userProfile,
    [DR_GET_SETUP_TIME]: state => state.setupTime,
    [DR_GET_TERMINALS]: state => state.terminals,
    [DR_GET_SUPPLIERS]: state => state.suppliers,
    [DR_GET_LOADINGS]: state => state.loadings,
    [DR_GET_SLOAT_PRICE_DATA]: state => state.slotPriceData || [],
    [DR_GET_CURRENT_TIME]: state => state.currentTime,
    [DR_GET_RUN_TIMER]: state => state.runTimer,
    [DR_GET_TIMESLOT_LIST]: state => state.confirmDriverTimeslotList,
    [DR_GET_AUTOCOMLETE_DATA]: state => state.autocompleteData,
    [DR_GET_AUTOCOMLETE_SUPPLIER]: state => state.autocompleteSupplier,
    [DR_GET_UNLOAD_ID]: state => state.unload_id,
    [DR_GET_FULL_FORM_WITH_AUTO]: state => state.fullFormWithAuto,
    [DR_GET_TIMESLOT_PAGINATION]: state =>
      state.confirmDriverTimeslotPagination,
  },
  actions: {
    [DR_FETCH_COMMON_SUPPLIERS_CHECK_INN]: ({ commit, state }, inn) => {
      commit(DR_SET_LOADINGS, true)

      return API.drivers
        .getCommonSuppliersCheckInn(state.token, inn)
        .then(data => data.data)
        .catch(error => console.log('error', error))
        .finally(setTimeout(() => commit(DR_SET_LOADINGS, false), 1000))
    },

    [DR_UPDATE_RUN_TIMER]: ({ commit }, payload) => {
      commit(DR_SET_RUN_TIMER, payload)
    },

    [DR_FETCH_CULTURES]: ({ commit, state }, unload_id) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getCultures(state.token, { ...unload_id, enablePages: 0 })
        .then(({ data }) => {
          commit(DR_SET_CULTURES, data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_FETCH_AUTOCOMLETE_DATA]: ({ commit, state }, unload_id) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getAutocomplete(state.token, unload_id)
        .then(({ data }) => {
          commit(DR_SET_AUTOCOMPLETE_DATA, data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_FETCH_AUTOCOMLETE_SUPPLIER]: ({ commit, state }) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getCommonSupplierAutocomplete(state.token)
        .then(({ data }) => {
          commit(DR_SET_AUTOCOMPLETE_SUPPLIER, data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_FETCH_TIMESLOT_LIST]: ({ commit, state }) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getTimeslots(state.token)
        .then(result => {
          commit(
            DR_SET_TIMESLOT_PAGINATION,
            result.headers['x-pagination-page-count'],
          )
          commit(DR_SET_TIMESLOT_LIST, result.data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_UPDATE_TIMESLOT_LIST]: ({ commit, state }, page) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getTimeslotsPage(state.token, page)
        .then(result => {
          commit(
            DR_SET_TIMESLOT_LIST,
            state.confirmDriverTimeslotList.concat(result.data),
          )
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_FETCH_USER_PROFILE]: ({ commit, state }) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getUserProfile(state.token)
        .then(({ data }) => {
          commit(DR_SET_USER_PROFILE, data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_UPDATE_CURRENT_TIME]: ({ commit }, data) => {
      commit(DR_SET_CURRENT_TIME, data)
    },

    [DR_UPDATE_SLOAT_PRICE_DATA]: ({ commit }) => {
      localStorage.removeItem('formPriceData')

      commit(DR_SET_SLOAT_PRICE_DATA, null)
    },
    [DR_FETCH_TERMINALS]: ({ commit, state }, full_list = 0) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getTerminals(state.token, full_list)
        .then(({ data }) => {
          commit(DR_SET_TERMINALS, data)
          commit(DR_SET_CURRENT_TIME, TIMER_TIME_SLOTS)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_FETCH_SUPPLIERS]: ({ commit, state }, unload_id) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .getSuppliers(state.token, { ...unload_id, 'per-page': 50 })
        .then(({ data }) => {
          commit(DR_SET_SUPPLIERS, data)
        })
        .catch(error => console.log('error', error))
        .finally(
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000),
        )
    },
    [DR_POST_TIME_REQUEST]: ({ commit }, data) => {
      commit(DR_SET_LOADINGS, true)
      commit(DR_SET_RUN_TIMER, false)
      commit(DR_UPDATE_TIMESLOTS_SUBMIT)
      commit(DR_SET_CHOSEN_UNLOAD_ID, data.unload_id)
      commit(DR_CLEAN_FULL_FORM_WITH_AUTO)

      API.drivers
        .postTimeRequest(data)
        .then(({ data }) => {
          if (isObject(data)) {
            commit(DR_SET_SLOAT_PRICE_DATA, [data])
          }
          if (isArray(data)) {
            commit(DR_SET_SLOAT_PRICE_DATA, data)
          }
          commit(DR_SET_CURRENT_TIME, TIMER_TIME_SLOTS)
          commit(DR_SET_RUN_TIMER, true)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000)
        })
    },
    [DR_POST_AUTOCOMPLETE_DATA]: ({ commit }, data) => {
      commit(DR_SET_LOADINGS, true)

      API.drivers
        .postAutocomplete(data)
        .then(({ data }) => {
          commit(DR_SET_AUTOCOMPLETE_DATA, data)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000)
        })
    },

    [DR_POST_TIMESLOTS_SUBMIT]: ({ state, commit, dispatch }, data) => {
      commit(DR_SET_LOADINGS, true)
      let id
      let timeslot_id

      state.slotPriceData.map(item => {
        if (item.timeslot_id === data.timeslot_id) {
          id = item.id
          timeslot_id = item.timeslot_id
        }
      })
      const config = { ...data }

      API.drivers
        .postTimeslotsSubmit(config)
        .then(() => {
          API.drivers
            .getTimeslotData({ id })
            .then(data => {
              commit(DR_UPDATE_SLOAT_PRICE_DATA_STATUS, data.data)
              let updateForm = state.fullFormWithAuto.filter(
                item => item.timeslot_id !== timeslot_id,
              )

              commit(DR_UPDATE_FULL_FORM_WITH_AUTO, updateForm)
            })
            .catch(error => console.log('error', error))
            .finally(() => {
              setTimeout(() => {
                commit(DR_SET_LOADINGS, false)
              }, 1000)
            })
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          setTimeout(() => {
            dispatch(DR_FETCH_TIMESLOT_LIST)
            commit(DR_SET_LOADINGS, false)
          }, 1000)
        })
    },

    [DR_REMOVE_TIMESLOT]: ({ commit, dispatch }, data) => {
      commit(DR_SET_LOADINGS, true)
      API.drivers
        .postRemoveTimeslot(data)
        .then(() => {
          commit(DR_SET_LOADINGS, true)
          dispatch(DR_FETCH_TIMESLOT_LIST)
          Message({
            message: 'Изменения успешно сохранены',
            type: 'success',
          })
        })
        .catch(error => {
          Message.error(error.data || 'Что-то пошло не так!')
        })
        .finally(() => {
          setTimeout(() => {
            commit(DR_SET_LOADINGS, false)
          }, 1000)
        })
    },

    /**
     * Actions для терминала СТЕПЬ.
     */
  },
  mutations: {
    [DR_SET_CULTURES]: (state, payload) => {
      state.cultures = payload
    },
    [DR_UPDATE_FULL_FORM_WITH_AUTO]: (state, payload) => {
      localStorage.setItem('fullFormWithAuto', JSON.stringify(payload))
      state.fullFormWithAuto =
        JSON.parse(localStorage.getItem('fullFormWithAuto')) || []
    },

    [DR_SET_FULL_FORM_WITH_AUTO]: (state, payload) => {
      const index = state.fullFormWithAuto.findIndex(
        n => n.timeslot_id === payload.timeslot_id,
      )

      if (index !== -1) {
        state.fullFormWithAuto.splice(index, 1)
      }
      state.fullFormWithAuto = [...state.fullFormWithAuto, payload]
      localStorage.setItem(
        'fullFormWithAuto',
        JSON.stringify(state.fullFormWithAuto),
      )
    },
    [DR_CLEAN_FULL_FORM_WITH_AUTO]: state => {
      state.fullFormWithAuto = []
      localStorage.setItem(
        'fullFormWithAuto',
        JSON.stringify(state.fullFormWithAuto),
      )
    },

    [DR_SET_CHOSEN_UNLOAD_ID]: (state, payload) => {
      state.unload_id = payload
    },
    [DR_SET_AUTOCOMPLETE_DATA]: (state, payload) => {
      state.autocompleteData = payload
    },
    [DR_SET_AUTOCOMPLETE_SUPPLIER]: (state, payload) => {
      state.autocompleteSupplier = payload.map(item => ({
        id: item.id,
        value: item.name,
        label: item.inn,
      }))
    },
    [DR_SET_TIMESLOT_LIST]: (state, payload) => {
      state.confirmDriverTimeslotList = payload
    },
    [DR_SET_TIMESLOT_PAGINATION]: (state, payload) => {
      state.confirmDriverTimeslotPagination = payload
    },
    [DR_SET_LOADINGS]: (state, payload) => {
      state.loadings = payload
    },
    [DR_SET_TERMINALS]: (state, payload) => {
      state.terminals = payload
    },
    [DR_SET_SUPPLIERS]: (state, payload) => {
      state.suppliers = payload
    },
    [DR_SET_SLOAT_PRICE_DATA]: (state, payload) => {
      state.slotPriceData = payload
      localStorage.setItem('formPriceData', JSON.stringify(state.slotPriceData))
      setTerminalId(state.unload_id)
    },

    [DR_UPDATE_SLOAT_PRICE_DATA_STATUS]: (state, payload) => {
      if (state.slotPriceData) {
        const index = state.slotPriceData.findIndex(
          n => n.timeslot_id === payload[0].timeslot_id,
        )

        if (index !== -1) {
          state.slotPriceData.splice(index, 1, payload[0])
          localStorage.setItem(
            'formPriceData',
            JSON.stringify(state.slotPriceData),
          )
        }
      }
    },

    [DR_SET_CURRENT_TIME]: (state, payload) => {
      state.currentTime = payload
    },

    [DR_SET_USER_PROFILE]: (state, payload) => {
      state.userProfile = payload
    },
    [DR_UPDATE_TIMESLOTS_SUBMIT]: state => {
      state.timeslotsSubmit = []
    },
    [DR_SET_RUN_TIMER]: (state, payload) => {
      state.runTimer = payload
    },
    [DR_UPDATE_SETUP_TIME]: (state, payload) => {
      state.setupTime = payload
    },
  },
}
