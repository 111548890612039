<template>
  <div id="app">
    <vue-extend-layouts />
  </div>
</template>

<script>
import { GET_IS_MOBILE } from '@/store/actions'
import { mapGetters } from 'vuex'
import VueExtendLayouts from 'vue-extend-layout'
import layoutWidthMixin from '@/layouts/layoutWidthMixin'
export default {
  name: 'App',
  metaInfo: {
    meta: [
      {
        vmid: 'adaptivity',
        name: 'viewport',
        content: 'width=device-width, initial-scale=1',
      },
    ],
  },
  components: {
    VueExtendLayouts,
  },
  mixins: [layoutWidthMixin],
  computed: {
    ...mapGetters({
      isMobile: GET_IS_MOBILE,
    }),
  },
}
</script>
