import {
  CHANGE_USERS_PAGINATION_NEW_PAGE,
  CHANGE_USERS_PAGINATION_PER_PAGE,
  CHANGE_USERS_REQUEST_FILTER_PARAMS,
  DELETE_USERS,
  FETCH_USERS,
  GET_IS_LOADING_USERS,
  GET_PROFILE_FROM_STATE,
  GET_USERS_FROM_STATE,
  GET_USERS_PAGINATION,
  POST_USER,
  SET_IS_LOADING_PROFILE,
  SET_PROFILE,
  SET_UPDATED_USERS,
  SET_USERS,
  SET_USERS_PAGINATION,
  SET_USERS_PAGINATION_CURRENT_PAGE,
  SET_USERS_PAGINATION_PER_PAGE,
  SET_USERS_REQUEST_FILTER_PARAMS,
  UNSHIFT_USERS,
  UPDATE_PROFILE,
} from '@/views/profile/store/actions'
import { GET_TERMINAL_CURRENT_ID, SET_DIALOG } from '@/store/actions'
import { getUserProfile, preparePagination, setProfileParams } from '@/core'
import { initialPagination } from '@/constants/pagination'
import { showMessageSuccess } from '@/UI/message'
import API from '@/api'

const profileFromLS = getUserProfile()

export default {
  state: {
    profile: {
      phone: profileFromLS?.phone || '',
      email: profileFromLS?.email || '',
      role: profileFromLS?.role || '',
      deleted_at: profileFromLS?.deleted_at || null,
      first_name: profileFromLS?.first_name || null,
      id: profileFromLS?.id || null,
      last_name: profileFromLS?.last_name || '',
      login: profileFromLS?.login || null,
      name: profileFromLS?.name || '',
      exporter_id: profileFromLS?.exporter_id || null,
    },
    users: [],
    isLoading: true,
    search: '',
    page: 1,
    pagination: initialPagination,
  },

  getters: {
    [GET_PROFILE_FROM_STATE]: state => state.profile,
    [GET_USERS_FROM_STATE]: state => state.users,
    [GET_IS_LOADING_USERS]: state => state.isLoading,
    [GET_USERS_PAGINATION]: state => state.pagination,
  },

  actions: {
    [FETCH_USERS]({ state, commit, rootGetters }) {
      const { pagination, search, page } = state
      const terminalCurrentId = rootGetters[GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        unload_id: terminalCurrentId,
        'per-page': pagination.perPage,
        search,
        page,
      }

      if (terminalCurrentId) {
        commit(SET_IS_LOADING_PROFILE, true)

        API.profile
          .getUsers(requestData)
          .then(({ headers, data }) => {
            const pagination = preparePagination(headers)

            commit(SET_USERS, data)
            commit(SET_USERS_PAGINATION, pagination)
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_PROFILE, false))
      }
    },
    [CHANGE_USERS_REQUEST_FILTER_PARAMS]({ commit, dispatch }, payload) {
      commit(SET_USERS_REQUEST_FILTER_PARAMS, payload)
      dispatch(FETCH_USERS)
    },
    [CHANGE_USERS_PAGINATION_PER_PAGE]({ commit, dispatch }, payload) {
      commit(SET_USERS_PAGINATION_PER_PAGE, payload)
      dispatch(FETCH_USERS)
    },
    [CHANGE_USERS_PAGINATION_NEW_PAGE]({ commit, dispatch }, payload) {
      commit(SET_USERS_PAGINATION_CURRENT_PAGE, payload)
      dispatch(FETCH_USERS)
    },
    [POST_USER](
      { commit, rootGetters },
      {
        name,
        surname,
        role,
        email,
        phone,
        password,
        setFormFields,
        nameDialog = '',
      },
    ) {
      const terminalCurrentId = rootGetters[GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        first_name: name,
        last_name: surname,
        role,
        email,
        phone,
        password,
      }

      commit(SET_IS_LOADING_PROFILE, true)

      API.profile
        .createUser(requestData)
        .then(({ data }) => commit(UNSHIFT_USERS, data))
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_DIALOG, { name: nameDialog })
          commit(SET_IS_LOADING_PROFILE, false)
          setFormFields()
        })
    },
    [DELETE_USERS](
      { dispatch, commit },
      { id, nameDialog = '', setFormFields = () => {} },
    ) {
      commit(SET_IS_LOADING_PROFILE, true)

      API.profile
        .deleteUser({ user_id: id })
        .then(() => dispatch(FETCH_USERS))
        .catch(error => console.log('error', error))
        .finally(() => {
          setFormFields()
          commit(SET_DIALOG, { name: nameDialog })
          commit(SET_IS_LOADING_PROFILE, false)
        })
    },
    [UPDATE_PROFILE](
      { commit, rootGetters },
      {
        id = null,
        name,
        surname,
        role,
        email,
        phone,
        password,
        isOrdinaryUser,
        clearPassword = () => {},
        setFormFields = () => {},
        nameDialog = '',
      },
    ) {
      const unload_id = rootGetters[GET_TERMINAL_CURRENT_ID] || null

      commit(SET_IS_LOADING_PROFILE, true)

      const requestData = {
        role,
        email,
        phone,
        password,
        unload_id,
        first_name: name,
        last_name: surname,
      }

      // если нет user_id тогда пользыватель редактирует сам себя
      if (!isOrdinaryUser && id) {
        requestData.user_id = id
      }

      API.profile
        .updateProfile(requestData)
        .then(({ data }) => {
          if (isOrdinaryUser) {
            setProfileParams(data)
            commit(SET_PROFILE, data)
          } else {
            commit(SET_UPDATED_USERS, data)
          }
        })
        .then(() => showMessageSuccess('Профиль обновлен'))
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_DIALOG, { name: nameDialog })
          clearPassword()
          commit(SET_IS_LOADING_PROFILE, false)
          setFormFields()
        })
    },
  },

  mutations: {
    [SET_USERS](state, payload) {
      state.users = payload
    },
    [SET_PROFILE](state, payload) {
      state.profile = payload
    },
    [UNSHIFT_USERS](state, payload) {
      state.users.unshift(payload)
    },
    [SET_UPDATED_USERS](state, payload) {
      state.users = state.users.map(user =>
        user.id === payload.id ? payload : user,
      )
    },
    [SET_IS_LOADING_PROFILE](state, payload) {
      state.isLoading = payload
    },
    [SET_USERS_REQUEST_FILTER_PARAMS](state, payload) {
      state.search = payload.search
    },
    [SET_USERS_PAGINATION](state, payload) {
      state.pagination = payload
    },
    [SET_USERS_PAGINATION_PER_PAGE](state, payload) {
      state.pagination.perPage = payload
    },
    [SET_USERS_PAGINATION_CURRENT_PAGE](state, payload) {
      state.page = payload
    },
  },
}
