import { Request, getParamsUrl } from '@/core'
import { supervisor, supervisorSamples } from '@/constants/urls'

export default {
  getSupervisorStatisticTable: params =>
    Request.get(
      `${supervisor.supervisorStatisticTable}${getParamsUrl(params)}`,
    ),
  getSupervisorQuotas: params =>
    Request.get(`${supervisor.getSupervisorQuotas}${getParamsUrl(params)}`),
  getSupervisorQuotasLines: params =>
    Request.get(
      `${supervisor.getSupervisorQuotasLines}${getParamsUrl(params)}`,
    ),
  postSupervisorQuotas: data =>
    Request.post(supervisor.postSupervisorQuotas, data),
  addSupervisorQuotasLine: data =>
    Request.post(supervisor.postSupervisorQuotasLine, data),
  deleteSupervisorQuotasLine: params =>
    Request.delete(
      `${supervisor.deleteSupervisorQuotasLine}${getParamsUrl(params)}`,
    ),
  putSupervisorQuotas: data =>
    Request.put(supervisor.putSupervisorQuotas, { data }),
  deleteSupervisorQuotas: data =>
    Request.delete(`${supervisor.deleteSupervisorQuotas}`, {
      data,
    }),

  getSupervisorArchive: params =>
    Request.get(`${supervisor.getSupervisorArchive}${getParamsUrl(params)}`),

  // шаблоны правил вызова
  getSupervisorSamples: params =>
    Request.get(`${supervisorSamples.samplesList}${getParamsUrl(params)}`),
  saveSupervisorSample: data =>
    Request.post(supervisorSamples.postSample, data),
  deleteSupervisorSample: data =>
    Request.delete(`${supervisorSamples.deleteSample}${getParamsUrl(data)}`),
  applySupervisorSamples: data =>
    Request.post(supervisorSamples.applySamples, data),
}
