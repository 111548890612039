export const SET_NEW_SETTINGS = '[settings] SET_NEW_SETTINGS'
export const SET_IS_LOADING_SETTINGS = '[settings] SET_IS_LOADING_SETTINGS'
export const SET_EDITOR_TEXT = '[settings] SET_EDITOR_TEXT'
export const UPDATE_EDITOR_TEXT = '[settings] UPDATE_EDITOR_TEXT'
export const GET_IS_LOADING_SETTINGS = '[settings] GET_IS_LOADING_SETTINGS'
export const FETCH_EDITOR_TEXT = '[settings] FETCH_EDITOR_TEXT'
export const GET_EDITOR_TEXT = '[settings] GET_EDITOR_TEXT'
export const SET_UNLOAD_INFO = '[settings] SET_UNLOAD_INFO'
export const GET_UNLOAD_INFO = '[settings] GET_UNLOAD_INFO'
export const FETCH_UNLOAD_INFO = '[settings] SET_UNLOAD_INFO'
