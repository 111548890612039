import Vue from 'vue'

import '@/assets/styles/main.sass'
import '@/plugins'
import globalMixin from '@/mixins/globalMixin'

import { UpperCase } from '@/directives/globalDirectives'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.mixin(globalMixin)
Vue.directive('uppercase', UpperCase)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueYandexMetrika from 'vue-yandex-metrika'

if (
  process.env.NODE_ENV === 'production' &&
  process.env.VUE_APP_YANDEX_METRIKA
) {
  Vue.use(VueYandexMetrika, {
    id: process.env.VUE_APP_YANDEX_METRIKA,
    router,
    env: process.env.NODE_ENV,
    debug: process.env.NODE_ENV !== 'production',
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    },
  })
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
