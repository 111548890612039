import * as actionsCommon from '@/store/actions'
import {
  CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE,
  CHANGE_TIMESLOTS_PAGINATION_PER_PAGE,
  CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS,
  CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS,
  CONFIRM_TIMESLOT,
  DELETE_TIMESLOT,
  FETCH_COMMON_SUPPLIERS_CHECK_INN,
  FETCH_MANAGER_EXCEL_REPORT,
  FETCH_TIMESLOT,
  FETCH_TIMESLOTS,
  FORCE_ARRIVAL_TIMESLOT,
  GET_COMMON_SUPPLIERS_CHECK_INN,
  GET_IS_LOADING_CALL_DRIVER,
  GET_IS_LOADING_POST_TIMESLOTS,
  GET_IS_LOADING_TIMESLOTS,
  GET_MANUALS_INFO,
  GET_TERMINAL_QUOTA_FILTER,
  GET_TIMESLOTS_CURRENT_STEP,
  GET_TIMESLOTS_FROM_STATE,
  GET_TIMESLOTS_PAGINATION,
  GET_TIMESLOTS_STATISTIC_FROM_STATE,
  POST_HAND_TIMESLOT,
  POST_TIMESLOT,
  SET_COMMON_SUPPLIERS_CHECK_INN,
  SET_IS_LOADING_CALL_DRIVER,
  SET_IS_LOADING_POST_TIMESLOTS,
  SET_IS_LOADING_TIMESLOTS,
  SET_MANUALS_INFO,
  SET_TIMESLOTS,
  SET_TIMESLOTS_PAGINATION,
  SET_TIMESLOTS_PAGINATION_CURRENT_PAGE,
  SET_TIMESLOTS_PAGINATION_PER_PAGE,
  SET_TIMESLOTS_QUOTA_FILTER,
  SET_TIMESLOTS_REQUEST_FILTER_PARAMS,
  SET_TIMESLOTS_REQUEST_SORT_PARAMS,
  SET_TIMESLOTS_STATISTICS,
  SET_TIMESLOT_CURRENT_STEP,
  SET_UPDATED_TIMESLOT,
  UNSHIFT_TIMESLOT,
  UPDATE_TIMESLOT,
} from '@/views/manager/store/actions'
import {
  MANAGER_ADD_TIMESLOT_HAND,
  MANAGER_TIMESLOT_VIEWING,
  MANAGER_TIMESLOT_WRONG,
  STEP_MANAGER_ADD_TIMESLOT_HAND,
} from '@/constants/dialogs'
import { Message, MessageBox } from 'element-ui'
import {
  diffTimeFromNowTz,
  downloadRegistry,
  getActiveStatus,
  preparePagination,
} from '@/core'
import { initialPagination } from '@/constants/pagination'
import {
  managerColumnActions,
  managerColumnGetters,
  managerColumnMutations,
  managerColumnState,
} from '@/views/manager/store/manager-columns'
import {
  managerTimeSlotDetailActions,
  managerTimeSlotDetailGetters,
  managerTimeSlotDetailMutations,
  managerTimeSlotDetailState,
} from '@/views/manager/store/manager-time-slot-detail'
import { showMessageError, showMessageSuccess } from '@/UI/message'

import API from '@/api'

let loaderCallDriver = null
const initialTimeslotsStatistic = {
  detached: null,
  parked_close: null,
  parked_far: null,
}

export default {
  state: {
    ...managerColumnState,
    ...managerTimeSlotDetailState,
    common_supplier_id: null,
    timeslots: [],
    timeslotsStatistic: initialTimeslotsStatistic,
    isLoading: true,
    isLoadingPostTimeslot: false,
    isLoadingCallDriver: false,
    plate_truck: '',
    culture_id: null,
    supplier_id: null,
    time_from: null,
    time_to: null,
    truck_status: '',
    quota_id: null,
    sort: '',
    page: 1,
    pagination: initialPagination,
    currentStep: {
      step: 1,
      data: {},
    },
    manuals_info: {
      manuals_enabled: 0,
      manuals_limit: 0,
      manuals_used: 0,
    },
  },

  getters: {
    ...managerColumnGetters,
    ...managerTimeSlotDetailGetters,
    [GET_TIMESLOTS_FROM_STATE]: state => state.timeslots,
    [GET_TIMESLOTS_STATISTIC_FROM_STATE]: state => state.timeslotsStatistic,
    [GET_IS_LOADING_TIMESLOTS]: state => state.isLoading,
    [GET_IS_LOADING_POST_TIMESLOTS]: state => state.isLoadingPostTimeslot,
    [GET_IS_LOADING_CALL_DRIVER]: state => state.isLoadingCallDriver,
    [GET_TIMESLOTS_PAGINATION]: state => state.pagination,
    [GET_TIMESLOTS_CURRENT_STEP]: state => state.currentStep,
    [GET_COMMON_SUPPLIERS_CHECK_INN]: state => state.common_supplier_id,
    [GET_MANUALS_INFO]: state => state.manuals_info,
    [GET_TERMINAL_QUOTA_FILTER]: state => state.quota_id,
  },

  actions: {
    ...managerColumnActions,
    ...managerTimeSlotDetailActions,
    [FETCH_COMMON_SUPPLIERS_CHECK_INN]({ commit }, inn) {
      return API.timeslots
        .getCommonSuppliersCheckInn({ inn })
        .then(data => {
          commit(SET_COMMON_SUPPLIERS_CHECK_INN, data.data)

          return data.data
        })
        .catch(error => console.log('error', error))
    },
    [FETCH_TIMESLOTS]({ state, commit, rootGetters }) {
      const {
        pagination,
        plate_truck_like,
        culture_id,
        truck_status,
        sort,
        supplier_id,
        page,
        time_from,
        time_to,
        quota_id,
      } = state
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        unload_id: terminalCurrentId,
        'per-page': pagination.perPage,
        plate_truck_like,
        culture_id,
        truck_status,
        sort,
        supplier_id,
        page,
        time_from,
        time_to,
        parent_quota_id: quota_id,
      }

      if (terminalCurrentId) {
        commit(SET_IS_LOADING_TIMESLOTS, true)

        API.timeslots
          .get(requestData)
          .then(({ headers, data }) => {
            const pagination = preparePagination(headers)

            commit(SET_TIMESLOTS, data?.items || [])
            commit(
              SET_TIMESLOTS_STATISTICS,
              data?.statistic || initialTimeslotsStatistic,
            )
            commit(SET_TIMESLOTS_PAGINATION, pagination)
            commit(SET_MANUALS_INFO, data)
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_TIMESLOTS, false))
      }
    },
    [CHANGE_TIMESLOTS_REQUEST_FILTER_PARAMS]({ commit, dispatch }, payload) {
      commit(SET_TIMESLOTS_REQUEST_FILTER_PARAMS, payload)
      dispatch(FETCH_TIMESLOTS)
    },
    [CHANGE_TIMESLOTS_REQUEST_SORT_PARAMS]({ commit, dispatch }, payload) {
      commit(SET_TIMESLOTS_REQUEST_SORT_PARAMS, payload)
      dispatch(FETCH_TIMESLOTS)
    },
    [CHANGE_TIMESLOTS_PAGINATION_PER_PAGE]({ commit, dispatch }, payload) {
      commit(SET_TIMESLOTS_PAGINATION_PER_PAGE, payload)
      dispatch(FETCH_TIMESLOTS)
    },
    [CHANGE_TIMESLOTS_PAGINATION_NEW_PAGE]({ commit, dispatch }, payload) {
      commit(SET_TIMESLOTS_PAGINATION_CURRENT_PAGE, payload)
      dispatch(FETCH_TIMESLOTS)
    },
    [FETCH_TIMESLOT](
      { commit, rootGetters },
      { plate_truck, setFormFields = () => {}, nameDialog = '' },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        plate_truck,
      }

      commit(SET_IS_LOADING_POST_TIMESLOTS, true)

      API.timeslots
        .getCurrentTimeslot(requestData)
        .then(({ data }) => {
          commit(actionsCommon.SET_DIALOG, { name: nameDialog })
          const terminalCurrentSlug =
            rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID_SLUG] || null

          // если ТС нет вообще приходит null
          if (!data) {
            commit(actionsCommon.SET_DIALOG, {
              name:
                terminalCurrentSlug === 'step'
                  ? STEP_MANAGER_ADD_TIMESLOT_HAND
                  : MANAGER_ADD_TIMESLOT_HAND,
              visible: true,
              data: { plate_truck },
            })
          } else {
            // проверяем когда приехал водитель
            const { window_from, window_to } = data
            const arrivalPrefix =
              rootGetters[actionsCommon.GET_ARRIVAL_PREFIX] || 0
            const lateInterval =
              rootGetters[actionsCommon.GET_LATE_INTERVAL] || 0
            const isIncompleteParking =
              rootGetters[actionsCommon.GET_IS_INCOMPLETE_PARKING]

            // приехал вовремя или опоздал в пределах допустимого интервала опоздания
            // или приехал раньше в пределах допустимого интервала прибытия раньше
            if (
              (diffTimeFromNowTz(window_from) >= 0 &&
                diffTimeFromNowTz(window_to) <= 0) ||
              (diffTimeFromNowTz(window_to) > 0 &&
                diffTimeFromNowTz(window_to) <= lateInterval * 3600 * 1000) ||
              (diffTimeFromNowTz(window_from) < 0 &&
                Math.abs(diffTimeFromNowTz(window_from)) <=
                  arrivalPrefix * 60 * 1000)
            ) {
              commit(actionsCommon.SET_DIALOG, {
                name: MANAGER_TIMESLOT_VIEWING,
                visible: true,
                data,
              })
            }

            // приехал рано
            else if (diffTimeFromNowTz(window_from) < 0) {
              if (isIncompleteParking) {
                // для терминалов с неполной парковкой нельзя приехать раньше
                MessageBox.alert(
                  'Попробуйте подтвердить приезд позднее',
                  'Время таймслота еще не наступило',
                  {
                    confirmButtonText: 'Понятно',
                    customClass: 'arrival-box',
                    center: true,
                    showClose: false,
                  },
                )
              } else {
                // для терминалов с обычной парковкой уходим на создание ручного ТС
                commit(actionsCommon.SET_DIALOG, {
                  name: MANAGER_TIMESLOT_WRONG,
                  visible: true,
                  data: {
                    ...data,
                    isEarly: true,
                  },
                })
              }
            }

            // опоздал
            else if (diffTimeFromNowTz(window_to) > 0) {
              if (isIncompleteParking) {
                // для терминалов с неполной парковкой - уже ничего не сделаешь
                MessageBox.alert(
                  'Время подтверждения приезда по данному таймслоту вышло.',
                  'Невозможно подтвердить приезд',
                  {
                    confirmButtonText: 'Понятно',
                    customClass: 'arrival-box',
                    center: true,
                    showClose: false,
                  },
                )
              } else {
                // для терминалов с обычной парковкой уходим на создание ручного ТС
                commit(actionsCommon.SET_DIALOG, {
                  name: MANAGER_TIMESLOT_WRONG,
                  visible: true,
                  data: {
                    ...data,
                    isEarly: false,
                  },
                })
              }
            }
          }
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          setFormFields()
        })
    },
    // подтверждение приезда авто (кнопка в каждом таймслоте)
    [FORCE_ARRIVAL_TIMESLOT](
      { dispatch, commit, rootGetters },
      { timeslot_id, plate_truck },
    ) {
      const unload_id = rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID]
      const hasIncompleteParking =
        rootGetters[actionsCommon.GET_IS_INCOMPLETE_PARKING]

      commit(SET_IS_LOADING_POST_TIMESLOTS, true)
      let method = hasIncompleteParking
        ? 'toStatusArrived'
        : 'timeslotStatusParkedFar'
      let requestData = {
        unload_id,
        timeslot_id,
        plate_truck,
      }

      API.timeslots[method](requestData)
        .then(() => {
          dispatch(FETCH_TIMESLOTS)
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
        })
    },

    [CONFIRM_TIMESLOT](
      { dispatch, commit, rootGetters },
      {
        timeslot_id,
        unload_id,
        phone,
        num_auto,
        supplier_id,
        culture_id,
        truck_type_code,
        detach,
        setFormFields = () => {},
        nameDialog = '',
      },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const hasIncompleteParking =
        rootGetters[actionsCommon.GET_IS_INCOMPLETE_PARKING]
      let method = hasIncompleteParking
        ? 'toStatusArrived'
        : 'timeslotStatusParkedFar'

      const requestData = {
        unload_id: unload_id ? unload_id : terminalCurrentId,
        plate_truck: num_auto,
        timeslot_id,
        phone,
        supplier_id,
        culture_id,
        truck_type_code,
        detach,
      }

      commit(SET_IS_LOADING_POST_TIMESLOTS, true)

      API.timeslots[method](requestData)
        .then(() => {
          dispatch(FETCH_TIMESLOTS)
          commit(actionsCommon.SET_DIALOG, { name: nameDialog })
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          setFormFields()
        })
    },
    [POST_HAND_TIMESLOT](
      { commit, rootGetters, dispatch },
      {
        timeslot_id,
        plate_truck,
        culture_id,
        exporter_id,
        phone,
        detach,
        setFormFields,
        truck_type_code,
        nameDialog = '',
        truck_spindles_count,
        common_supplier_id,
        plate_trailer,
        name,
      },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const terminalCurrentSlug =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID_SLUG] || null
      const requestData =
        terminalCurrentSlug === 'step'
          ? {
              timeslot_id,
              unload_id: terminalCurrentId,
              culture_id,
              exporter_id,
              plate_truck,
              phone,
              detach,
              truck_type_code,
              common_supplier_id,
              truck_spindles_count,
              plate_trailer,
              name,
            }
          : {
              timeslot_id,
              unload_id: terminalCurrentId,
              culture_id,
              exporter_id,
              plate_truck,
              phone,
              detach,
              truck_type_code,
              common_supplier_id,
            }

      commit(SET_IS_LOADING_POST_TIMESLOTS, true)
      loaderCallDriver = setTimeout(() => {
        commit(SET_IS_LOADING_CALL_DRIVER, true)
        commit(actionsCommon.SET_DIALOG, { name: nameDialog })
      }, 5000)

      API.timeslots
        .timeslotCreate(requestData)
        .then(() => dispatch(FETCH_TIMESLOTS))
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          commit(SET_IS_LOADING_CALL_DRIVER, false)
          setFormFields()
          commit(actionsCommon.SET_DIALOG, { name: nameDialog })
          clearTimeout(loaderCallDriver)
        })
    },
    [POST_TIMESLOT](
      { commit, rootGetters, dispatch },
      {
        timeslot_id,
        plate_truck,
        culture_id,
        supplier_id,
        phone,
        setFormFields,
        truck_type_code,
        detach,
        nameDialog = '',
      },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        timeslot_id,
        unload_id: terminalCurrentId,
        culture_id,
        supplier_id,
        plate_truck,
        phone,
        detach,
        truck_type_code,
      }

      commit(SET_IS_LOADING_POST_TIMESLOTS, true)

      API.timeslots
        .timeslotPush(requestData)
        .then(() => dispatch(FETCH_TIMESLOTS))
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(actionsCommon.SET_DIALOG, { name: nameDialog })
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          setFormFields()
        })
    },
    [DELETE_TIMESLOT](
      { dispatch, commit },
      { timeslot_ids, refund, nameDialog = '' },
    ) {
      commit(SET_IS_LOADING_POST_TIMESLOTS, true)
      commit(SET_IS_LOADING_CALL_DRIVER, true)
      commit(actionsCommon.SET_DIALOG, { name: nameDialog })

      API.timeslots
        .timeslotDelete({ timeslot_ids, refund })
        .then(() => {
          dispatch(FETCH_TIMESLOTS)
          showMessageSuccess(
            timeslot_ids.length > 1 ? 'Таймслоты удалены!' : 'Таймслот удален!',
          )
        })
        .catch(error => Message.error(error.data || 'Что-то пошло не так!'))
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          commit(SET_IS_LOADING_CALL_DRIVER, false)
        })
    },
    [UPDATE_TIMESLOT](
      { commit, rootGetters },
      {
        timeslot_id,
        phone,
        truck_type_code,
        time,
        culture_id,
        exporter_id,
        supplier_id,
        detach = null,
        restore = null,
        setFormFields = () => {},
        nameDialog = '',
        truck_spindles_count,
        common_supplier_id,
        plate_trailer,
        name,
        plate_truck,
      },
    ) {
      commit(SET_IS_LOADING_POST_TIMESLOTS, true)
      commit(SET_IS_LOADING_CALL_DRIVER, true)
      const terminalCurrentSlug =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID_SLUG] || null

      const requestData =
        terminalCurrentSlug === 'step'
          ? {
              timeslot_id,
              culture_id,
              exporter_id,
              supplier_id,
              plate_truck,
              phone,
              detach,
              truck_type_code,
              truck_spindles_count,
              common_supplier_id,
              plate_trailer,
              name,
            }
          : {
              timeslot_id,
              truck_type_code,
              phone,
              time,
              plate_truck,
              culture_id,
              exporter_id,
              supplier_id,
              common_supplier_id,
            }

      if (detach) {
        requestData.detach = detach
      } else {
        requestData.restore = restore
      }

      API.timeslots
        .timeslotUpdate(requestData)
        .then(({ data }) => {
          commit(SET_UPDATED_TIMESLOT, data)
          commit(actionsCommon.SET_DIALOG, { name: nameDialog })
          setFormFields()
          showMessageSuccess('Таймслот обновлен!')
        })
        .finally(() => {
          commit(SET_IS_LOADING_POST_TIMESLOTS, false)
          commit(SET_IS_LOADING_CALL_DRIVER, false)
        })
    },
    [FETCH_MANAGER_EXCEL_REPORT]({ state, commit, rootGetters }, fields) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID]
      const {
        plate_truck_like,
        culture_id,
        truck_status,
        supplier_id,
        time_from,
        time_to,
      } = state

      const requestData = {
        unload_id: terminalCurrentId,
        plate_truck_like,
        culture_id,
        truck_status,
        supplier_id,
        time_from,
        time_to,
        fields,
      }

      commit(SET_IS_LOADING_TIMESLOTS, true)

      API.reports
        .getManagerExcelReport(requestData)
        .then(({ data }) => {
          showMessageSuccess('Отчет сформирован!')
          downloadRegistry({
            path: data.file_link,
            blank: 0,
            download: true,
          })
        })
        .catch(error => console.log('error', error))
        .finally(() => {
          commit(SET_IS_LOADING_TIMESLOTS, false)
        })
    },
  },

  mutations: {
    ...managerTimeSlotDetailMutations,
    ...managerColumnMutations,
    [SET_TIMESLOTS](state, payload) {
      state.timeslots = payload
    },
    [SET_COMMON_SUPPLIERS_CHECK_INN](state, payload) {
      state.common_supplier_id = payload
    },
    [SET_TIMESLOTS_STATISTICS](state, payload) {
      state.timeslotsStatistic = payload
    },
    [UNSHIFT_TIMESLOT](state, payload) {
      state.timeslots.unshift(payload)
    },
    [SET_UPDATED_TIMESLOT](state, payload) {
      state.timeslots = state.timeslots.map(timeslot =>
        timeslot.id === payload.id ? payload : timeslot,
      )
    },
    [SET_IS_LOADING_TIMESLOTS](state, payload) {
      state.isLoading = payload
    },
    [SET_IS_LOADING_POST_TIMESLOTS](state, payload) {
      state.isLoadingPostTimeslot = payload
    },
    [SET_IS_LOADING_CALL_DRIVER](state, payload) {
      state.isLoadingCallDriver = payload
    },
    [SET_MANUALS_INFO](state, payload) {
      state.manuals_info = {
        manuals_enabled: payload.manuals_limit_enabled,
        manuals_limit: payload.manuals_limit,
        manuals_used: payload.manuals_for_today,
      }
    },
    [SET_TIMESLOTS_REQUEST_FILTER_PARAMS](state, payload) {
      state.plate_truck_like = payload.search
      state.culture_id = payload.culture
      state.supplier_id = payload.exporter
      state.truck_status = getActiveStatus(payload.statuses)
      state.time_from = payload.time_from
      state.time_to = payload.time_to
      state.quota_id = payload.quota_id
    },
    [SET_TIMESLOTS_QUOTA_FILTER](state, payload) {
      state.quota_id = payload
    },
    [SET_TIMESLOTS_REQUEST_SORT_PARAMS](state, payload) {
      state.sort = payload
    },
    [SET_TIMESLOTS_PAGINATION](state, payload) {
      state.pagination = payload
    },
    [SET_TIMESLOTS_PAGINATION_PER_PAGE](state, payload) {
      state.pagination.perPage = payload
    },
    [SET_TIMESLOTS_PAGINATION_CURRENT_PAGE](state, payload) {
      state.page = payload
    },
    [SET_TIMESLOT_CURRENT_STEP](state, { step = 1, data = {} }) {
      state.currentStep = { step, data }
    },
  },
}
