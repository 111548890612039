import autos from '@/api/control/autos'
import blacklist from '@/api/control/blacklist'
import culture from '@/api/control/culture'
import exporters from '@/api/control/exporters'
import quotas from '@/api/control/quotas'
import suppliers from '@/api/control/suppliers'
import whitelist from '@/api/control/whitelist'

export default {
  culture,
  suppliers,
  autos,
  exporters,
  blacklist,
  quotas,
  whitelist,
}
