import { Request, getParamsUrl } from '@/core'
import { control } from '@/constants/urls'

export default {
  get: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${control.cultures}${paramsFormatted}`)
  },
  getActiveQuotasCultures: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${control.culturesActiveQuotasList}${paramsFormatted}`)
  },
  getPublic: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${control.culturesPublic}${paramsFormatted}`)
  },
  post: data => Request.post(control.culturesCreate, data),
  update: data => Request.put(`${control.culturesUpdate}?id=${data.id}`, data),
  delete: ids => Request.delete(`${control.culturesDelete}?id=${ids}`),
}
