import { ENV } from '@/constants/env'

const lsTokenKey = ENV.LS_KEY + '-login-token_' + ENV.VERSION
const lsUserRoleKey = ENV.LS_KEY + '-user-role'
const lsUserUnload = ENV.LS_KEY + '-user-unload'
const lsUserProfile = ENV.LS_KEY + '-user-profile'
const lsIsAndroid = ENV.LS_KEY + '-is-android'
const lsManagerTableCol = ENV.LS_KEY + '-manager-table-col'
const lsParkmanTableCol = ENV.LS_KEY + '-parkman-table-col'
const lsTerminalId = ENV.LS_KEY + '-terminal-id'

export const getLoginToken = () => localStorage.getItem(lsTokenKey)
export const getUserRole = () => localStorage.getItem(lsUserRoleKey)
export const getIsAndroid = () => localStorage.getItem(lsIsAndroid)
export const getUserUnload = () =>
  JSON.parse(localStorage.getItem(lsUserUnload))
export const getUserProfile = () =>
  JSON.parse(localStorage.getItem(lsUserProfile))

export const setIsAndroid = () => localStorage.setItem(lsIsAndroid, 'true')
export const setLoginParams = (token, role, default_unload, profile) => {
  localStorage.setItem(lsTokenKey, token)
  localStorage.setItem(lsUserRoleKey, role)
  localStorage.setItem(lsUserUnload, JSON.stringify(default_unload))
  localStorage.setItem(lsUserProfile, JSON.stringify(profile))
}
export const setProfileParams = profile =>
  localStorage.setItem(lsUserProfile, JSON.stringify(profile))

export const replaceCurrentTerminal = unload => {
  localStorage.setItem(lsUserUnload, JSON.stringify(unload))
}

export const removeLoginParams = () => {
  localStorage.removeItem(lsTokenKey)
  localStorage.removeItem(lsUserRoleKey)
  localStorage.removeItem(lsUserUnload)
  localStorage.removeItem(lsIsAndroid)
  localStorage.removeItem(lsUserProfile)
}

// Manager table
export const setManagerTableColumns = data =>
  localStorage.setItem(lsManagerTableCol, JSON.stringify(data))
export const getManagerTableColumns = () =>
  JSON.parse(localStorage.getItem(lsManagerTableCol))

// Parkman table
export const setParkmanTableColumns = data =>
  localStorage.setItem(lsParkmanTableCol, JSON.stringify(data))
export const getParkmanTableColumns = () =>
  JSON.parse(localStorage.getItem(lsParkmanTableCol))

// for ConfirmAddAuto (Driver GetTimeslots)
export const setTerminalId = data => localStorage.setItem(lsTerminalId, data)
export const getTerminalId = () => localStorage.getItem(lsTerminalId)
export const removeTerminalId = () => localStorage.removeItem(lsTerminalId)
