import { Request, getParamsUrl } from '@/core'
import { drivers, timeslots } from '@/constants/urls'

export default {
  get: params => {
    const paramsFormatted = getParamsUrl(params)

    /** при запросе с фильтром по номеру авто добавляем информацию по удалеенным тс
     * а она лежит на отдельном роуте **/
    if (params?.plate_truck_like) {
      return Request.get(`${timeslots.timeslotsHasDeleted}${paramsFormatted}`)
    }

    return Request.get(`${timeslots.timeslots}${paramsFormatted}`)
  },
  getBuffer: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${timeslots.buffer}${paramsFormatted}`)
  },

  getCommonSuppliersCheckInn: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${drivers.commonSuppliersCheckInn}${paramsFormatted}`)
  },
  toStatusArrived: data => Request.post(timeslots.statusArrived, data),
  getCurrentTimeslot: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${timeslots.currentTimeslot}${paramsFormatted}`)
  },
  timeslotCreate: params =>
    Request.post(timeslots.timeslotCreate, { ...params }),
  timeslotPush: params => Request.post(timeslots.timeslotPush, { ...params }),
  timeslotDelete: data => Request.delete(timeslots.timeslotDelete, { data }),
  timeslotUpdate: params => Request.post(timeslots.timeslotUpdate, params),
  timeslotStatusParkedFar: params =>
    Request.post(timeslots.timeslotStatusParkedFar, params),
  timeslotFromBufferToParking: params =>
    Request.post(timeslots.timeslotFromBufferToParking, params),
  getLogsByTimeslot: params =>
    Request.get(`${timeslots.getLogsByTimeslot}${getParamsUrl(params)}`),
  setDetachTimeslot: params =>
    Request.post(timeslots.detachTimeslot, { ...params }),
  checkHandLimit: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${timeslots.handTimeslotRest}${paramsFormatted}`)
  },
}
