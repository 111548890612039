export const TIMER_TIME_SLOTS = 600

export const timeForSelect = hour => {
  let arr = []
  let i = 0

  while (i < hour) {
    arr.push({
      label: `c ${i <= 9 ? '0' : ''}${i}:00 по ${i <= 8 ? '0' : ''}${i + 1}:00`,
      value: `${i <= 9 ? '0' : ''}${i}:00`,
      name: `c ${i <= 9 ? '0' : ''}${i}:00 по ${i <= 8 ? '0' : ''}${i + 1}:00`,
      id: `${i <= 9 ? '0' : ''}${i}:00`,
    })
    i++
  }

  return arr
}

export const HOURS_SELECT_OPTIONS = [
  {
    label: 'c 00:00 по 01:00',
    value: '00:00',
  },
  {
    label: 'c 01:00  по 02:00',
    value: '01:00',
  },
  {
    label: 'c 02:00  по 03:00',
    value: '02:00',
  },
  {
    label: 'c 03:00  по 04:00',
    value: '03:00',
  },
  {
    label: 'c 04:00  по 05:00',
    value: '04:00',
  },
  {
    label: 'c 05:00  по 06:00',
    value: '05:00',
  },
  {
    label: 'c 06:00  по 07:00',
    value: '06:00',
  },
  {
    label: 'c 07:00  по 08:00',
    value: '07:00',
  },
  {
    label: 'c 08:00  по 09:00',
    value: '08:00',
  },
  {
    label: 'c 09:00  по 10:00',
    value: '09:00',
  },
  {
    label: 'c 10:00  по 11:00',
    value: '10:00',
  },
  {
    label: 'c 11:00  по 12:00',
    value: '11:00',
  },
  {
    label: 'c 12:00  по 13:00',
    value: '12:00',
  },
  {
    label: 'c 13:00  по 14:00',
    value: '13:00',
  },
  {
    label: 'c 14:00  по 15:00',
    value: '14:00',
  },
  {
    label: 'c 15:00  по 16:00',
    value: '15:00',
  },
  {
    label: 'c 16:00  по 17:00',
    value: '16:00',
  },
  {
    label: 'c 17:00  по 18:00',
    value: '17:00',
  },
  {
    label: 'c 18:00  по 19:00',
    value: '18:00',
  },
  {
    label: 'c 19:00  по 20:00',
    value: '19:00',
  },
  {
    label: 'c 20:00  по 21:00',
    value: '20:00',
  },
  {
    label: 'c 21:00  по 22:00',
    value: '21:00',
  },
  {
    label: 'c 22:00  по 23:00',
    value: '22:00',
  },
  {
    label: 'c 23:00  по 24:00',
    value: '23:00',
  },
]
