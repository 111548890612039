import {
  GET_ACTIVE_TABLE_COLUMN,
  SET_ACTIVE_TABLE_COLUMN_ACTION,
  SET_ACTIVE_TABLE_COLUMN_MUTATION,
} from '@/views/manager/store/actions'
import { getManagerTableColumns, setManagerTableColumns } from '@/core'

export const managerColumnState = {
  activeColumns: getManagerTableColumns() || [0, 1, 2, 3, 4],
}
export const managerColumnGetters = {
  [GET_ACTIVE_TABLE_COLUMN]: state => state.activeColumns,
}
export const managerColumnActions = {
  [SET_ACTIVE_TABLE_COLUMN_ACTION]: ({ commit }, payload) => {
    setManagerTableColumns(payload)
    commit(SET_ACTIVE_TABLE_COLUMN_MUTATION, payload)
  },
}
export const managerColumnMutations = {
  [SET_ACTIVE_TABLE_COLUMN_MUTATION]: (state, payload) => {
    state.activeColumns = payload
  },
}
