import { Request, getParamsUrl } from '@/core'
import { profile } from '@/constants/urls'

export default {
  getUsers(params) {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${profile.getUsers}${paramsFormatted}`)
  },
  updateUser: data => Request.put(profile.updateUser, data),
  deleteUser: ({ user_id }) =>
    Request.delete(profile.deleteUser, { data: { user_id } }),
  createUser: data => Request.post(profile.createUser, data),
  updateProfile: data => Request.put(profile.updateUser, data),
  getProfile: () => Request.get(profile.getUserData),
}
