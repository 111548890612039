import * as actionsCommon from '@/store/actions'
import {
  ADD_SAMPLE,
  DELETE_SAMPLE,
  FETCH_SAMPLES,
  GET_IS_LOADING_SAMPLES,
  GET_SAMPLES,
  SET_IS_LOADING_SAMPLES,
  SET_SAMPLES_LIST,
  UPDATE_EXPORTER_SAMPLE,
} from '@/views/quota-samples/store/actions'
import { showMessageSuccess } from '@/UI/message'
import API from '@/api'

export default {
  state: {
    samples: [],
    isLoading: false,
  },
  getters: {
    [GET_SAMPLES]: state => state.samples,
    [GET_IS_LOADING_SAMPLES]: state => state.isLoading,
  },
  actions: {
    [FETCH_SAMPLES]({ commit, rootGetters }, exporter_id = undefined) {
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      if (unload_id) {
        commit(SET_IS_LOADING_SAMPLES, true)

        API.quotaSamples
          .get({ unload_id, exporter_id })
          .then(({ data }) => commit(SET_SAMPLES_LIST, data))
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_SAMPLES, false))
      }
    },
    [ADD_SAMPLE](
      { commit, dispatch, rootGetters },
      { data, callback = () => {} },
    ) {
      const unload_id =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null

      const requestData = {
        ...data,
        unload_id,
      }

      if (unload_id) {
        commit(SET_IS_LOADING_SAMPLES, true)

        API.quotaSamples
          .addSample(requestData)
          .then(async ({ data }) => {
            // в случае необходимости принудительно распределяем все ТС в общую квоту
            // (если не нужно - callback приходит пустой функцией)
            await callback(data.id, data.trucks_allowed_count)
            dispatch(FETCH_SAMPLES)
            showMessageSuccess('Шаблон добавлен')
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(SET_IS_LOADING_SAMPLES, false))
      }
    },

    [UPDATE_EXPORTER_SAMPLE]({ commit, dispatch }, data) {
      commit(SET_IS_LOADING_SAMPLES, true)

      API.quotaSamples
        .updateSample(data)
        .then(() => showMessageSuccess('Шаблон обновлён'))
        .catch(error => console.log('error', error))
        .finally(() => {
          dispatch(FETCH_SAMPLES)
          commit(SET_IS_LOADING_SAMPLES, false)
        })
    },

    [DELETE_SAMPLE]({ commit, dispatch }, data) {
      commit(SET_IS_LOADING_SAMPLES, true)

      API.quotaSamples
        .deleteSample(data)
        .then(() => dispatch(FETCH_SAMPLES))
        .then(() => showMessageSuccess('Шаблон удалён'))
        .catch(error => console.log('error', error))
        .finally(() => commit(SET_IS_LOADING_SAMPLES, false))
    },
  },
  mutations: {
    [SET_SAMPLES_LIST]: (state, payload) => {
      if (payload.length) {
        const prepareQuotas = payload.reduce((accum, item) => {
          let isNewItem = true

          accum = accum.map(accumItem => {
            if (
              accumItem[0].time_from === item.time_from &&
              accumItem[0].time_to === item.time_to
            ) {
              accumItem.push(item)
              isNewItem = false
            }

            return accumItem
          })

          if (isNewItem) {
            accum.push([item])
          }

          return accum
        }, [])

        if (prepareQuotas.length > 1) {
          prepareQuotas.sort((a, b) => {
            if (a[0].time_from < b[0].time_from) {
              return -1
            }
            if (a[0].time_from > b[0].time_from) {
              return 1
            }

            return 0
          })
        }

        prepareQuotas.forEach(item => {
          if (item[0].time_to === '23:59') {
            item[0].time_to = '24:00'
          }
        })

        state.samples = prepareQuotas
      } else {
        state.samples = []
      }
    },
    [SET_IS_LOADING_SAMPLES]: (state, payload) => (state.isLoading = payload),
  },
}
