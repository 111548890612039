import * as actionsCommon from '@/store/actions'
import API from '@/api'

import * as actions from '../actions'
import { initialPagination } from '@/constants/pagination'
import { preparePagination } from '@/core'
import { showMessageSuccess } from '@/UI/message'

export default {
  state: {
    isLoading: false,
    whitelist: [],
    pagination: initialPagination,
  },
  getters: {
    [actions.GET_WHITELIST_PAGE]: state => state.whitelist,
    [actions.GET_WHITELIST_PAGINATION]: state => state.pagination,
    [actions.GET_IS_LOADING_WHITELIST]: state => state.isLoading,
  },
  actions: {
    [actions.FETCH_WHITELIST_PAGE]({ commit, rootGetters }, payload) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = {
        unload_id: terminalCurrentId,
        ...payload,
      }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_WHITELIST, true)

        API.control.whitelist
          .get(requestData)
          .then(({ headers, data }) =>
            commit(actions.SET_WHITELIST_PAGE, {
              data,
              pagination: preparePagination(headers),
            }),
          )
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_WHITELIST, false))
      }
    },
    [actions.ADD_WHITELIST](
      { commit, dispatch },
      { requestArray, callback, params },
    ) {
      commit(actions.SET_IS_LOADING_WHITELIST, true)

      API.control.whitelist
        .create(requestArray)
        .then(() => {
          dispatch(actions.FETCH_WHITELIST_PAGE, params)
          showMessageSuccess(
            requestArray.data.length === 1
              ? 'Запись успешно добавлена'
              : 'Записи успешно добавлены',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_WHITELIST, false))
    },

    [actions.UPDATE_WHITELIST](
      { commit, dispatch },
      { requestData, callback, params },
    ) {
      commit(actions.SET_IS_LOADING_WHITELIST, true)

      API.control.whitelist
        .update(requestData)
        .then(() => {
          dispatch(actions.FETCH_WHITELIST_PAGE, params)
          showMessageSuccess('Запись успешно обновлена')
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_WHITELIST, false))
    },
    [actions.DELETE_WHITELIST]({ commit, dispatch }, { id, callback, params }) {
      commit(actions.SET_IS_LOADING_WHITELIST, true)

      API.control.whitelist
        .delete(id)
        .then(() => {
          dispatch(actions.FETCH_WHITELIST_PAGE, params)
          showMessageSuccess(
            id.length > 1 ? 'Записи были удалены' : 'Запись была удалена',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_WHITELIST, false))
    },
  },
  mutations: {
    [actions.SET_IS_LOADING_WHITELIST](state, payload) {
      state.isLoading = payload
    },
    [actions.SET_WHITELIST_PAGE](state, { data, pagination }) {
      state.whitelist = data
      state.pagination = pagination
    },
  },
}
