import { Request, getParamsUrl } from '@/core'
import { terminal } from '@/constants/urls'

export default {
  get: () => Request.get(terminal.terminals),

  getTerminalQueuePage: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${terminal.terminalQueuePage}${paramsFormatted}`)
  },

  getTerminalQueuePosition: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${terminal.terminalQueuePosition}${paramsFormatted}`)
  },

  sendTerminalPing: () => Request.get(terminal.terminalPing),
}
