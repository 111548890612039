import * as actionsCommon from '@/store/actions'
import { showMessageSuccess } from '@/UI/message'
import API from '@/api'

import * as actions from '../actions'
import { arrToObj, preparePagination } from '@/core'
import { initialPagination } from '@/constants/pagination'

export default {
  state: {
    isLoading: false,
    culturesAll: [],
    prepareCultures: {},
    cultures: [],
    pagination: initialPagination,
  },
  getters: {
    [actions.GET_CULTURE_FOR_TABLE]: state => state.cultures,
    [actions.GET_CULTURE_PAGINATION]: state => state.pagination,
    [actions.GET_CULTURE_FROM_STATE]: state => state.culturesAll,
    [actions.GET_PREPARE_CULTURE_FROM_STATE]: state => state.prepareCultures,
    [actions.GET_IS_LOADING_CULTURE]: state => state.isLoading,
  },
  actions: {
    [actions.FETCH_CULTURES]({ commit, rootGetters }) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = { unload_id: terminalCurrentId, enablePages: 0 }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_CULTURE, true)

        API.control.culture
          .get(requestData)
          .then(data => commit(actions.SET_CULTURES, data.data))
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_CULTURE, false))
      }
    },
    [actions.FETCH_CULTURES_PAGE]({ commit, rootGetters }, payload) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID] || null
      const requestData = { unload_id: terminalCurrentId, ...payload }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_CULTURE, true)

        API.control.culture
          .get(requestData)
          .then(({ headers, data }) =>
            commit(actions.SET_CULTURES_PAGE, {
              data,
              pagination: preparePagination(headers),
            }),
          )
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_CULTURE, false))
      }
    },
    [actions.POST_CULTURE](
      { commit, dispatch, rootGetters },
      { requestData, callback, params },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID]
      const data = { unload_id: terminalCurrentId, ...requestData }

      if (terminalCurrentId) {
        commit(actions.SET_IS_LOADING_CULTURE, true)
        API.control.culture
          .post(data)
          .then(({ data }) => {
            dispatch(actions.FETCH_CULTURES, params)
            dispatch(actions.FETCH_CULTURES_PAGE, params)
            commit(actions.SET_NEW_CULTURES, data)
            showMessageSuccess('Культура была создана')
            callback()
          })
          .catch(error => console.log('error', error))
          .finally(() => commit(actions.SET_IS_LOADING_CULTURE, false))
      }
    },
    [actions.PUT_CULTURE](
      { commit, rootGetters },
      { id, requestData, callback },
    ) {
      const terminalCurrentId =
        rootGetters[actionsCommon.GET_TERMINAL_CURRENT_ID]
      const data = {
        id,
        unload_id: terminalCurrentId,
        ...requestData,
      }

      API.control.culture
        .update(data)
        .then(({ data }) => {
          commit(actions.SET_PUT_CULTURES, data)
          showMessageSuccess('Культура была обновлена')
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_CULTURE, false))
    },
    [actions.DELETE_CULTURE]({ commit, dispatch }, { ids, callback, params }) {
      API.control.culture
        .delete(ids)
        .then(() => {
          dispatch(actions.FETCH_CULTURES_PAGE, params)
          dispatch(actions.FETCH_CULTURES)
          showMessageSuccess(
            ids.length > 1 ? 'Культуры были удалены' : 'Культура была удалена',
          )
          callback()
        })
        .catch(error => console.log('error', error))
        .finally(() => commit(actions.SET_IS_LOADING_CULTURE, false))
    },
  },
  mutations: {
    [actions.SET_IS_LOADING_CULTURE](state, payload) {
      state.isLoading = payload
    },
    [actions.SET_CULTURES](state, payload) {
      state.culturesAll = payload
      state.prepareCultures = arrToObj(payload)
    },
    [actions.SET_CULTURES_PAGE](state, { data, pagination }) {
      state.cultures = data
      state.pagination = pagination
    },
    [actions.SET_NEW_CULTURES](state, payload) {
      state.prepareCultures[payload.id] = payload
    },
    [actions.SET_PUT_CULTURES](state, item) {
      if (item?.id) {
        const index = state.cultures.findIndex(e => e.id === item.id)

        if (index !== -1) {
          state.cultures.splice(index, 1, item)
        }
        if (state.prepareCultures[item.id]) {
          state.prepareCultures[item.id] = item
        }
      }
    },
  },
}
