import {
  GET_IS_AUTH,
  GET_ROLE,
  SET_LOGIN_PARAMS,
  SET_LOGOUT,
} from '@/store/actions'
import { IS_PROD } from '@/constants/env'
import { removeLoginParams, setIsAndroid, setLoginParams } from '@/core'
import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'

/** Обработка ошибок при дублировании роута **/
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  duplicateNavigationPolicy: 'ignore',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition ? savedPosition : { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to?.meta?.role?.includes('public')) {
    return next()
  }

  if (to.path === '/payment-doc') {
    return next()
  }

  const authPage = to.name === 'Login'
  const isToken = store.getters[GET_IS_AUTH]
  const currentRole = store.getters[GET_ROLE]
  const urlToken = to.query.token

  /** блок работы с роутами терминала **/
  const terminalFirstPage = to.name === 'terminal'
  const terminalOtherPages = to.name && to.name.slice(0,8) === 'terminal' && to.name.length > 8

  // при входе на терминал предварительно разлогиниваемся в любом случае
  if (terminalFirstPage) {
    await removeLoginParams()
    await store.commit(SET_LOGOUT)

    return next()
  }

  // на остальных страницах терминала просто идем дальше, игнорируя все проверки
  if (terminalOtherPages) {
    if (isToken) {
      return next()
    }
    return next('/terminal')
  }

  /** Проверка на наличие параметра token в урле и авторизация для водителя в андроиде */
  if (urlToken) {
    await setLoginParams(urlToken, 'driver', 1, null)
    await setIsAndroid()
    store.commit(SET_LOGIN_PARAMS, [urlToken, 'driver'])

    return next('/drivers')
  }

  /** Проверка на наличие токена */
  if (!isToken && !authPage) {
    await removeLoginParams()
    await store.commit(SET_LOGOUT)

    return next('/login')
  }

  /** Проверка на наличие роутов с текущей ролью */
  const roleRoutes =
    currentRole === 'root'
      ? routes
      : routes.filter(r => {
          if (
            r.role?.includes(currentRole) ||
            (r.role?.includes('all') &&
              !authPage &&
              (currentRole !== 'admin' || currentRole !== 'superadmin'))
          ) {
            return r
          }
        })

  if (!roleRoutes.length && (isToken || Boolean(currentRole))) {
    await removeLoginParams()
    await store.commit(SET_LOGOUT)

    return next()
  }

  /** Проверка на наличие доступа к роуту */
  const route = roleRoutes.find(r =>
    r.children
      ? Boolean(r.children.find(ch => ch.name === to.name))
      : r.name === to.name,
  )

  if (roleRoutes.length && !route) {
    return next(roleRoutes[0].path)
  }

  const prefixTitle = IS_PROD ? 'IQ PORT TRANZIT - ' : 'IQ ТЕСТОВЫЙ СЕРВЕР - '

  document.title = `${prefixTitle}${to.meta.title}`

  next()
})

export default router
