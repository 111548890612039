import { Request, getParamsUrl } from '@/core'
import { quotaSamples } from '@/constants/urls'

export default {
  // шаблоны квот экспортера (родительская квота)
  get: params => {
    const paramsFormatted = getParamsUrl(params)

    return Request.get(`${quotaSamples.list}${paramsFormatted}`)
  },
  addSample: data => Request.post(quotaSamples.addSample, data),
  updateSample: data => Request.post(quotaSamples.updateSample, data),
  deleteSample: data =>
    Request.delete(`${quotaSamples.deleteSample}${getParamsUrl(data)}`, {
      data,
    }),

  // шаблоны квот поставщика (дочерние)
  saveSupplierSample: data =>
    Request.post(quotaSamples.saveSupplierSample, data),
  deleteSupplierSample: template_quota_id =>
    Request.post(quotaSamples.deleteSample, { template_quota_id }),

  // применение шаблона квот
  applySamples: data => Request.post(quotaSamples.applySamples, data),
}
