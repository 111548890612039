import {
  GET_DRAWER,
  GET_UPDATE_ACTION,
  SET_DRAWER,
  SET_DRAWERS_CLEAN,
  SET_UPDATE_ACTION,
} from '@/store/actions'

export default {
  state: {
    drawers: {},
    updateAction: '',
  },
  getters: {
    [GET_DRAWER]: state => name => state.drawers[name] || {},
    [GET_UPDATE_ACTION]: state => state.updateAction,
  },
  mutations: {
    [SET_DRAWER]: (state, { name, visible = false, data = {} }) => {
      state.drawers = { ...state.drawers, [name]: { visible, data } }
    },
    [SET_DRAWERS_CLEAN]: state => (state.drawers = {}),
    [SET_UPDATE_ACTION]: (state, payload = '') => {
      state.updateAction = payload
    },
  },
}
